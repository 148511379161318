import { Fragment, useContext, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import StripePortal from '../stripe/StripePortal';
import { useUser } from '../../swrHooks/useUser';
import { UserIdContext } from '../../contexts/UserIdContext';
import { Link } from 'react-router-dom';
import Loading from '../loading/loading';

export default function StripeFailure() {
  const [open, setOpen] = useState(true);

  const { id, userToken } = useContext(UserIdContext);
  const { currentUser, isLoadingData } = useUser(
    id as string,
    userToken as string
  );
  const history = useHistory();

  const renderModalButton = (): JSX.Element => {
    // console.log(currentUser.stripeCustomerId);

    return currentUser.stripeCustomerId ? (
      <StripePortal />
    ) : (
      <Link to='/home'>
        <button
          type='button'
          className='inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'
          onClick={() => setOpen(false)}
        >
          Go to home
        </button>
      </Link>
    );
  };

  return isLoadingData ? (
    <Loading />
  ) : (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-10 inset-0 overflow-y-auto'
        open={open}
        onClose={() => {
          setOpen(false);
          history.push('/home');
        }}
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6'>
              <div>
                <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100'>
                  <XIcon className='h-6 w-6 text-red-600' aria-hidden='true' />
                </div>
                <div className='mt-3 text-center sm:mt-5'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg leading-6 font-medium text-gray-900'
                  >
                    Payment failed
                  </Dialog.Title>
                  <div className='mt-2'>
                    <p className='text-sm text-gray-500'>
                      Oops! Something went wrong with your payment.
                      hey@harvel.io with any questions!
                    </p>
                  </div>
                </div>
              </div>
              <div className='mt-5 sm:mt-6'>{renderModalButton()}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
