import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';

let auth0_domain: string = process.env.REACT_APP_AUTH0_DOMAIN as string;
let auth0_clientId: string = process.env.REACT_APP_AUTH0_CLIENT_ID as string;

// let c = {
//   domain: auth0_domain,
//   clientId: auth0_clientId
// }
// This work
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithHistory domain={auth0_domain} clientId={auth0_clientId} redirectUri={window.location.origin + "/home"}>
        {/* <Auth0ProviderWithHistory children={c}> */}
        <App />
        {/* <Auth0ProviderWithHistory > */}
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
