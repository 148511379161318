import { FC, useContext } from 'react'
import { IUserContent } from '../../shared/interfaces';
import { PlusIcon } from '@heroicons/react/solid'
import { useState } from 'react';
import { SelectedUserIdContext } from '../../contexts/SelectedUserIdContext';
import { addRetrievedUrlToUserContent } from '../../services/userContentService';
import { UserIdContext } from '../../contexts/UserIdContext';
import { IRetrievedResults } from './../../shared/interfaces';
import { ResultNotificationContext } from '../../contexts/ResultNotificationContext';
import { getUserContentById } from '../../services/adminService';

const AdminDashAddNewResult:FC<{selectedContent: IUserContent | null ,updateSelectedContent: (newContent: IUserContent | null) => void  }> = ({selectedContent, updateSelectedContent}) => {

    const [url, setUrl] = useState('');
    const { selectedUserId } = useContext(SelectedUserIdContext);
    const { userToken } = useContext(UserIdContext);
    const {setNotMessage, setSuccess, setShowNotification } = useContext(ResultNotificationContext);


    const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        setUrl(event.currentTarget.value);
    }

    const addResult = async () => {
        const RetrievedResult: IRetrievedResults = {
            url: url,
            verified: true,
            isManual: false
        };
        const status = await addRetrievedUrlToUserContent(selectedUserId, userToken as string, selectedContent?._id as string, RetrievedResult);
        if(status === 200) {
            const theContent = await getUserContentById(selectedUserId, selectedContent?._id as string, userToken as string)
            updateSelectedContent(theContent);
        } else {
            setNotMessage('Something went wrong please try again');
            setSuccess(false);
            setShowNotification(true);
        }
        setUrl('');
    }

    return (
        <div className="mt-6 mb-4">
      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
        Add Found Link
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <input
            type="text"
            name="email"
            id="email"
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md  sm:text-sm border-gray-300"
            placeholder="Enter the found url here"
            value={url}
            onChange={handleInputChange}
          />
        </div>
        <button 
        className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        onClick={addResult}
        >
          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          <span>Add</span>
        </button>
      </div>
    </div>
    )
}

export default AdminDashAddNewResult;