import { createContext } from 'react';

export type DeleteContent = {
  openDeleteContentModal: boolean;
  setOpenDeleteContentModal: (open: boolean) => void;
};

export const DeleteContentContext = createContext<DeleteContent>({
  openDeleteContentModal: false,
  setOpenDeleteContentModal: () => {},
});
