export const whitelistedDomains = [         // * domains which are not permitted when adding a manual request
    "google.com",
    "twitter.com",
    "facebook.com",
    "instagram.com",
    "gumroad.com",
    "pinterest.com",
    "br.pinterest.com",
    "youtube.com",
    "udemy.com",
    "pluralsight.com",
    "lynda.com",
    "skillshare.com",
    "apple.com",
    "crunchbase.com",
    "hootsuite.com",
    "dribbble.com",
    "buymeacoffee.com",
    "convertkit.com",
    "books.google.com",
    "patreon.com",
    // "github.com",
    "reddit.com",
    "behance.net",
    "dribbble.com",
    "etsy.com",
    "aws.amazon.com",
    "creativemarket.com",
    "pinterest.ca.com",
    "procreate.brushes.work",
    "pixelbuddha.net",
    "pinterest.ch",
    "folio.procreate.art",
    "procreate.brushes.work",
    "brushes.work",
    "speckyboy.com",
    "pinterest.ch",
    "pinterest.dk",
    "brainstudy.info",
    "bardotbrush.com",
    "vk.com",
    "thehotskills.com",
    "byamandakay.com",
    "designbundles.net",
    "cubebrush.co",
    "jaejohns.com",
    "designcuts.com",
    "retrosupply.co",
    "tutsplus.com",
    "adobe.com",
    "conceptartempire.com",
    "amazon.com",
    "apps.apple.com",
    "medium.com",
    "justcreative.com",
    "brusheezy.com",
    "delightfuldesignstudio.com",
    "designcuts.com",
    "brainstudy.info",
    "app.gumroad.com",
    "elements.envato.com",
    "grinfer.com",
    "courseduck.com",
    "coursemarks.com",
    "bundletop.com",
    "restless.co.uk",
    "redbubble.com",
    "comidots.net",
    "videohive.net",
    "graphicriver.net",
    "quora.com",
    "freepik.com",
    "in.pinterest.com",
    "domestika.com",
    'blendermarket.com',
    'blendernation.com',
    'yelp.com',
    'themeforest.net',
    'codecanyon.net'
  ];
