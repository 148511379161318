import { FC, useState, useEffect, useContext, Fragment } from "react";
import { IUserContent } from "../../shared/interfaces";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { changeFirstTakedownStatus } from '../../services/adminService';
import { UserIdContext } from "../../contexts/UserIdContext";
import { SelectedUserIdContext } from "../../contexts/SelectedUserIdContext";



function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }

const takedownStatusOptions = [
    'True',
    'False',
    'Already Happened'
]


const AdminDashContentInfo: FC<{selectedContent: IUserContent | null, updateSelectedContent: (newContent: IUserContent | null) => void }> = ({selectedContent, updateSelectedContent}) => {
    
    const {userToken } = useContext(UserIdContext);
    const { selectedUserId } = useContext(SelectedUserIdContext)
    const [selected, setSelected] = useState<string | undefined>();

    useEffect(() => {
        setSelected(selectedContent?.firstTakedownStatus);
    }, [selectedContent]);

    const changeTakedownStatus = async (status: string) => {
        const oldStatus = selected;
        setSelected(status);
        const resStatus = await changeFirstTakedownStatus(selectedUserId, selectedContent?._id as string, userToken as string);
        if(resStatus !== 200) {
            setSelected(oldStatus);
        }
    }

    
    return (
        <>
        <div className='mt-6 px-4 py-5 border-b border-gray-200 sm:px-6'>
            <div className='-ml-10 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap'>
                <div className='ml-4 mt-4'>
                    <h3 className='text-lg leading-6 font-medium text-gray-900'>
                    {selectedContent?.title}
                    </h3>
                    <p className='mt-1 text-sm text-gray-500'>
                    {selectedContent?.url}
                    </p>
                </div>
                <div className='ml-4 mt-4 flex-shrink-0 flex flex-row'>
                    <Listbox value={selected} onChange={changeTakedownStatus}>
                        {({ open }) => (
                            <>
                            <Listbox.Label className="block text-sm font-medium text-red-500 mr-3 mt-3">Only Change From False To True!</Listbox.Label>
                            <div className="mt-1 relative">
                                <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <span className="block truncate">{selected}</span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                                </Listbox.Button>

                                <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                >
                                <Listbox.Options
                                    static
                                    className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                >
                                    {takedownStatusOptions.map((status, ind) => (
                                    <Listbox.Option
                                        key={ind}
                                        className={({ active }) =>
                                        classNames(
                                            active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                            'cursor-default select-none relative py-2 pl-8 pr-4'
                                        )
                                        }
                                        value={status}
                                    >
                                        {({ selected, active }) => (
                                        <>
                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                            {status}
                                            </span>

                                            {selected ? (
                                            <span
                                                className={classNames(
                                                active ? 'text-white' : 'text-indigo-600',
                                                'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                                )}
                                            >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                            ) : null}
                                        </>
                                        )}
                                    </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                                </Transition>
                            </div>
                            </>
                        )}
                    </Listbox>        
                    {/* <button
                    onClick={() => {
                        
                    }}
                    type='button'
                    className='relative inline-flex items-center px-3 py-1 ml-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50'
                    >
                    Update Takedown Status
                    </button> */}
                </div>
            </div>
        </div> 
        </>
    )
}

export default AdminDashContentInfo;