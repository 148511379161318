import { FC } from "react";


const BlogPost: FC<{image: string, label: string, title: string, link: string}> = ({image,label, title, link}) => {

    return (
        <div className="mb-6 w-full lg:w-1/2 px-3">
                  <div className="rounded overflow-hidden shadow">
                    <img
                      className="lg:h-48 rounded-t"
                      src={image}
                      alt=""
                    />
                    <div className="p-6 rounded-b bg-white">
                      <span className="text-sm text-gray-400">
                        {label}
                      </span>
                      <h2 className="my-2 text-2xl font-bold">
                        {title}
                      </h2>
                      <a
                        className="text-brandcolor hover:text-brandblue font-bold"
                        href={link}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
        </div>
    )
}

export default BlogPost;