import {  XIcon, PlusIcon } from '@heroicons/react/solid'
import { useContext, useState } from 'react';
import { UserIdContext } from '../../contexts/UserIdContext';
import { useUser } from '../../swrHooks/useUser';
import { Warning } from '../warning/Warning';
import UsersService from './../../services/usersService';
import {Formik, Form} from 'formik';
import { filterUrlValidation } from '../../validation/ValidationSchema';

 
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Filters = () => {
    
    const [errorMsg, setErrorMsg] = useState(false);
    const { id, userToken } = useContext(UserIdContext);
    const { currentUser, mutate, checkUserInfo } = useUser(
    id as string,
    userToken as string
  );
    const { addFilterToUser, removeFilterFromUser }  = UsersService


    const removeUrlFromFilter = async (filtered_url: string) => {
      const res = await removeFilterFromUser(id as string, userToken as string, filtered_url)
      if (res === 200) {
        const newFilterList = currentUser.filters?.filter(url => url !== filtered_url);
        mutate({...currentUser, filters: newFilterList}, false);
      }
    }
 
    return (
         
        checkUserInfo(currentUser) ?
        <>
        <div className="pb-5 border-b border-gray-200 mt-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Whitelisting</h3>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">
          Add URLs where you sell your content. They'll be whitelisted and won't be detected by our search algorithm.
        </p>
      </div>
        <div className="space-y-1">
        <Formik 
        validateOnChange
        enableReinitialize
        validationSchema={filterUrlValidation}
        initialValues={{ filtered_url: '' }}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            if(currentUser.filters?.includes(data.filtered_url)) {
              setErrorMsg(true)
              setTimeout(() => {setErrorMsg(false)}, 3000)
            } else {
            const res = await addFilterToUser(id as string, userToken as string, data.filtered_url)
            if (res === 200) { 
              const newFilterList = currentUser.filters?.push(data.filtered_url);
              mutate({...currentUser, newFilterList }, true);
              setSubmitting(false);
              resetForm();
            }} 
        }}
        >
        {({
            values,
            isSubmitting,
            handleChange,
            handleSubmit,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
        <label htmlFor="add_filters" className="block text-sm mb-1 font-medium mt-3 text-gray-700">
            Add
        </label>
        <div className="flex mb-3">
            <div className="flex-grow">
            <input
                type="text"
                name="filtered_url"
                id="filtered_url"
                // className="block w-full shadow-sm sm:text-sm z-10 border-gray-300 rounded-md"
                className={classNames(errors.filtered_url ? 'border-red-500' : 
                'border-gray-300', 'block w-full shadow-sm sm:text-sm rounded-md' )}
                placeholder="Enter URL to Whitelist"
                value={values.filtered_url}
                onChange={handleChange}
            />
            { errors.filtered_url && touched.filtered_url ? <p className='mt-3 text-sm text-red-600' id='email-error'>
                      {errors.filtered_url}
                    </p> :
                    (errorMsg ? <p className='mt-3 text-sm text-red-600' id='email-error'>Url already exists</p> : <p></p>)
                    }
            </div>
            <span className="ml-3">
            <button
                disabled={isSubmitting}
                type="submit"
                className="bg-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
            >
                <PlusIcon className="-ml-2 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                <span>Add</span>
            </button>
            </span>
        </div>
        </Form>
        )}
        </Formik>
        </div>
        <div>
      <div className="flow-root mt-6">
      { currentUser.filters ?
        <ul className="-my-5 divide-y divide-gray-200">
          {currentUser.filters.map((filter) => (
            <li key={filter} className="py-4">
              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">{filter}</p>
                </div>
                <div>
                  <button
                    onClick={() => {removeUrlFromFilter(filter)}}
                    className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-red-500 bg-white hover:bg-gray-50"
                  >
                    <XIcon className="w-4 h-4"></XIcon>
                  </button>
                </div>
              </div>
            </li>    
          ))} 
        </ul> : <></> }
      </div>
    </div>
    </>
     : <Warning
     message={
       'In order to add whitelisted urls, you have add content first'
     }
   />
     );
}



export default Filters;
