import { FC, Fragment, useContext, useState } from 'react';
import { UserIdContext } from '../../contexts/UserIdContext';
import { Listbox, Transition } from '@headlessui/react';
import { IUserContent, IUserInfosAdminDash } from '../../shared/interfaces';
import UsersService from '../../services/usersService'
import {
    SelectorIcon,
    CheckIcon
  } from '@heroicons/react/solid';
import { SelectedUserIdContext } from '../../contexts/SelectedUserIdContext';
import { getUserContentById } from '../../services/adminService'


function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}
  


const AdminDashDropdowns: FC<{ userInfos: Array<IUserInfosAdminDash>, selectedContent: IUserContent,
                            updateSelectedContent: (newContent: IUserContent | null) => void }> = ({ userInfos, selectedContent, updateSelectedContent }) => {


    const [selectedUser, setSelectedUser] = useState<IUserInfosAdminDash | null>(null);
    const [selectedUserContents, setSelectedUserContents] = useState<IUserContent[] | null>([]);
    const { userToken } = useContext(UserIdContext);
    const { selectedUserId, setSelectedUserId } = useContext(SelectedUserIdContext);

    const onUserDropdownChange = async (user: IUserInfosAdminDash) => {
        if(selectedUser?.id !== user.id) {
            setSelectedUser(user);
            const result = await UsersService.findUserById(`/user/findUserById/${user.id}`, userToken as string);
            setSelectedUserContents(result.content);
            updateSelectedContent(null);    // * reset selected content in parent component (adminDash)
            setSelectedUserId(result._id);
        }
    }

    const onContentDropdownChange = async (content: IUserContent) => {
        const theContent = await getUserContentById(selectedUserId, content._id, userToken as string)
        updateSelectedContent(theContent);
    }

    return (
        <> 
        <div className="items-center grid grid-cols-1 sm:grid-cols-2">
            {/* USERS DROPDOWN */}
            <div className='mr-0 sm:mr-3'>
            <Listbox value={selectedUser} onChange={onUserDropdownChange}>
                {({ open }) => (
                <>
                    <Listbox.Label className='mt-3 block text-sm font-medium text-gray-700'>
                    Choose user       
                    </Listbox.Label>
                    
                    <div className='mt-1 relative '>
                    <Listbox.Button className='h-9 bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
                        <span className='block truncate'>
                        {selectedUser?.displayName}
                        </span>
                        <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                        <SelectorIcon
                            className='h-5 w-5 text-gray-400'
                            aria-hidden='true'
                        />
                        </span>
                    </Listbox.Button>

                    <Transition
                        show={open}
                        as={Fragment}
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Listbox.Options
                        static
                        className='absolute mt-1 w-full z-10 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'
                        >


                        {userInfos.map(userInfo => ( 
                            <Listbox.Option
                                key={userInfo.id}
                                className={({ active }) =>
                                    classNames(
                                    active
                                        ? 'text-white bg-indigo-600'
                                        : 'text-gray-900',
                                    'cursor-default select-none relative py-2 pl-3 pr-9'
                                    )
                                }
                                value={userInfo}
                            >
                            {({ selected, active }) => (
                                <>
                                <span
                                    className={classNames(
                                    selected
                                        ? 'font-semibold'
                                        : 'font-normal',
                                    'block truncate'
                                    )}
                                >
                                    {userInfo.displayName}
                                </span>

                                {selected ? (
                                    <span
                                    className={classNames(
                                        active
                                        ? 'text-white'
                                        : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                    >
                                    <CheckIcon
                                        className='h-5 w-5'
                                        aria-hidden='true'
                                    />
                                    </span>
                                ) : null}
                                </>
                            )}
                            </Listbox.Option>
                        ))}

                        
                        </Listbox.Options>
                    </Transition>
                    </div>
                </>
                )}
            </Listbox>
            </div>



        

            {/* CONTENTS DROPDOWN  */}
            <div>
            <Listbox value={selectedContent} onChange={onContentDropdownChange}>
                {({ open }) => (
                <>
                    {/* <Listbox.Label className='w-96 mt-3 block text-sm font-medium text-gray-700'> */}
                    <Listbox.Label className=' mt-3 block text-sm font-medium text-gray-700'>
                    Choose content       
                    </Listbox.Label>
                    
                    <div className='mt-1 relative'>
                    <Listbox.Button className='h-9 bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
                        <span className='block truncate'>
                        {selectedContent?.title}
                        </span>
                        <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                        <SelectorIcon
                            className='h-5 w-5 text-gray-400'
                            aria-hidden='true'
                        />
                        </span>
                    </Listbox.Button>

                    <Transition
                        show={open}
                        as={Fragment}
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Listbox.Options
                        static
                        className='absolute mt-1 w-full z-10 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'
                        >


                        {selectedUserContents?.map(content => ( 
                            <Listbox.Option
                                key={content._id}
                                className={({ active }) =>
                                    classNames(
                                    active
                                        ? 'text-white bg-indigo-600'
                                        : 'text-gray-900',
                                    'cursor-default select-none relative py-2 pl-3 pr-9'
                                    )
                                }
                                value={content}
                            >
                            {({ selected, active }) => (
                                <>
                                <span
                                    className={classNames(
                                    selected
                                        ? 'font-semibold'
                                        : 'font-normal',
                                    'block truncate'
                                    )}
                                >
                                    {content.title}    
                                </span>

                                {selected ? (
                                    <span
                                    className={classNames(
                                        active
                                        ? 'text-white'
                                        : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                    >
                                    <CheckIcon
                                        className='h-5 w-5'
                                        aria-hidden='true'
                                    />
                                    </span>
                                ) : null}
                                </>
                            )}
                            </Listbox.Option>
                        ))}

                        
                        </Listbox.Options>
                    </Transition>
                    </div>
                </>
                )}
            </Listbox>
            </div>
            </div>
        </>
    )

}

export default AdminDashDropdowns;