import axios from '../axios/axios-config';

export const createCheckoutSession = async (
  userId: string,
  priceId: string
) => {
  const res = await axios.post('/stripe/createCheckoutSession', {
    userId,
    priceId,
  });
  return res.data;
};

/**
 * TODO: create openCustomerPortal function that redirects to the Stripe Portal
 * @param sessionID
 */

export const openCustomerPortal = async (
  stripeCustomerId: string,
  token: string
) => {
  const result = await axios.get(
    `/stripe/openCustomerPortal/${stripeCustomerId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result.data;
};
