import { FC } from 'react';
import toucanloading from "../../shared/toucanload.gif";

const Loading: FC<{
  minimised?: boolean
}> = ({minimised}) =>  {
  return (
    <div className={minimised ? "flex" : "flex h-screen"}>
      <div className="m-auto">
        <img className="w-30 h-30" src={toucanloading} alt=""></img>
      </div>
    </div>
  );
};

export default Loading;
