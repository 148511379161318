import { createContext } from 'react';

export type SelectedUserId = {
    selectedUserId: string,
    setSelectedUserId: (id: string) => void
}

export const SelectedUserIdContext = createContext<SelectedUserId>({
    selectedUserId: '',
    setSelectedUserId: () => {}
});