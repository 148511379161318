import { FC, useContext, useEffect, useState } from "react";
import { useUser } from "../../../swrHooks/useUser";
import { UserIdContext } from "../../../contexts/UserIdContext";
import { CreditCardIcon, CheckCircleIcon } from "@heroicons/react/solid";

const UserMessage: FC<{}> = () => {

  const { id, userToken } = useContext(UserIdContext);
  const { isLoadingData, currentUser } = useUser(
    id as string,
    userToken as string
  );
  const [planMessage, setPlanMessage] = useState("");

  useEffect(() => {
    const checkPlan = (): void => {
      switch(currentUser.stripeSubscriptionPlan) {
        case 'plan 1':
          setPlanMessage('Lite Package');
          break;
        case 'plan 2':
          setPlanMessage('Creator Package');
          break;
        case 'plan 3':
          setPlanMessage('Pro Package');
          break;
        case 'plan custom':
          setPlanMessage('Custom Package');
          break;
        case 'appSumoPlan1':
          setPlanMessage('Tier 1 - AppSumo Lifetime');
          break;
        case 'appSumoPlan2':
          setPlanMessage('Tier 2 - AppSumo Lifetime');
          break;
        case 'appSumoPlan3':
          setPlanMessage('Tier 3 - AppSumo Lifetime');
          break;            
        default:
          setPlanMessage('No plan yet')
      }
    }
    isLoadingData ? setPlanMessage("Loading plan") : checkPlan();
  }, [isLoadingData, currentUser.stripeSubscriptionPlan]);

  return (
    <div className="bg-white shadow-lg">
      <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div className="py-6 md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <div className="flex items-center">
              <img
                className="hidden h-16 w-16 rounded-full sm:block"
                src={currentUser.image}
                alt=""
              />
              <div>
                <div className="flex items-center">
                  <img
                    className="h-16 w-16 rounded-full sm:hidden"
                    src={currentUser.image}
                    alt=""
                  />
                  <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                    {`Hello there, ${currentUser.displayName}`}
                  </h1>
                </div>
                <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                  <dt className="sr-only">Subscription plan</dt>
                  <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                    <CreditCardIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {planMessage}
                  </dd>
                  <dt className="sr-only">Account status</dt>
                  <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                    <CheckCircleIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                      aria-hidden="true"
                    />
                    Verified account
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMessage;
