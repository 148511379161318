import { FC } from 'react';

const Pager: FC<{page: number, setPage: (page: number) => void, canLoadMore: boolean}> = ({page, setPage, canLoadMore}) => {

  return (
     <div className='flex mt-6 justify-between'>
        <div className="mt-3">
            <button onClick={() => setPage(page - 1)} disabled={page===1} > {"<"} </button>
        </div>
        <div className="mt-3">
            Page {page}
        </div>
        <div className="mt-3">
            <button onClick={() => setPage(page + 1)}  disabled={!canLoadMore}> {">"} </button>
        </div>
      </div>  
  );
};

export default Pager