import { FC, Fragment, useContext, useState } from "react";
import {
  CheckCircleIcon,
  DocumentSearchIcon,
  SelectorIcon,
  XIcon,
} from "@heroicons/react/solid";
import harvelBlue from "../../shared/images/harvel-logo-blue.png";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { UserIdContext } from "../../contexts/UserIdContext";
import { useUser } from "../../swrHooks/useUser";
import { CheckIcon } from "@heroicons/react/outline";
import { Listbox, Transition } from "@headlessui/react";
import { addRetrievedUrlToUserContent } from "../../services/userContentService";
import { IRetrievedResults, IUserContent } from "../../shared/interfaces";
import { Warning } from "../warning/Warning";
import { whitelistedDomains } from "../../shared/whitelistedDomains";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Requests: FC<{}> = () => {
  const { id, userToken } = useContext(UserIdContext);
  const { currentUser, checkUserInfo } = useUser(
    id as string,
    userToken as string
  );
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState<IUserContent>(
    currentUser!.content!.filter((content) => content.active)[0]
  );

  const validationSchema = Yup.object({
    content_url: Yup.string()
      .url('The provided input is not an url!')
      .test('is-url-whitelisted', 'This domain is whitelisted, please contact support to add it for you.',

      function(value) {
        if (value !== undefined) {
          for(let wd of whitelistedDomains){
            const value_as_array = value.trim().split("/");
            if(value_as_array?.includes(wd)) return false;
          }
        } else {
            return true;
        }
        return true;
      })

      .required('This field is required'),
  });

  if (!checkUserInfo(currentUser)) {
    return (
      <Warning
        message={
          "Please add content first, to be able to submit an infringed url to your already saved content"
        }
      />
    );
  }

  if(currentUser.content?.filter(cnt => cnt.active).length === 0) {
    return <Warning
      message="You don't have any active contents at the moment!"
    />
  }

  return (
    <>
      {/* <div className="pb-5 border-b border-gray-200 mt-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Found a website where your content is infringed?
        </h3>
        <p className="mt-2 max-w-4xl text-md text-gray-500">
          Add the website URL and select the content it is related to. <br></br>
          Feel free to reach out using the live chat if you have any trouble
          doing so!{" "}
          <span className="font-semibold">
            We are always at your disposal! 🥰
          </span>
        </p>
      </div> */}
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md bg-white w-full space-y-8 shadow-md rounded py-10 p-6">
          <div>
            <img
              className="mx-auto h-10 w-auto"
              src={harvelBlue}
              alt="Harvel"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-brandblue">
              Manual Request
            </h2>
            <p
              className="mt-2 max-w-4xl text-md text-center
           text-gray-500"
            >
              Found a site that infringes your content? Report it here and we
              will take action on your behalf!
            </p>
          </div>
          <Formik
            validateOnChange
            enableReinitialize
            initialValues={{ content_url: "" }}
            validationSchema={validationSchema}
            onSubmit={async (data, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              // async call
              const RetrievedResult: IRetrievedResults = {
                url: data.content_url,
                verified: true,
                isManual: true,
              };
              // console.log(selected._id);
              const status = await addRetrievedUrlToUserContent(
                id as string,
                userToken as string,
                selected._id,
                RetrievedResult
              );
              if (status === 200) setShow(true);
              // TODO: if status is false
              setSubmitting(false);
              resetForm();
            }}
          >
            {({
              values,
              isSubmitting,
              handleChange,
              handleSubmit,
              errors,
              touched,
            }) => (
              <Form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label
                      htmlFor="content_url"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Content Url *
                    </label>
                    <input
                      type="text"
                      name="content_url"
                      id="content_url"
                      className={classNames(
                        errors.content_url
                          ? "border-red-500"
                          : "border-gray-300",
                        "focus:ring-indigo-500 h-10 focus:border-indigo-500 block w-full sm:text-sm  rounded-md"
                      )}
                      placeholder="https://yourcontenturl.com/"
                      onChange={handleChange}
                      value={values.content_url}
                    />
                  </div>
                  {errors.content_url && touched.content_url ? (
                    <p className="mt-3 text-sm text-red-600" id="email-error">
                      {errors.content_url}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  <div>
                    <Listbox value={selected} onChange={setSelected}>
                      {({ open }) => (
                        <>
                          <Listbox.Label className="mt-3 block text-sm font-medium text-gray-700">
                            Choose one of your tracked contents
                          </Listbox.Label>
                          <div className="mt-1 relative">
                            <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <span className="block truncate">
                                {selected.title}
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options
                                static
                                className="absolute mt-1 w-full z-10 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                              >
                                {currentUser.content
                                  ?.filter((content) => content.active)
                                  .map((content) => (
                                    <Listbox.Option
                                      key={content._id}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "text-white bg-indigo-600"
                                            : "text-gray-900",
                                          "cursor-default select-none relative py-2 pl-3 pr-9"
                                        )
                                      }
                                      value={content}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={classNames(
                                              selected
                                                ? "font-semibold"
                                                : "font-normal",
                                              "block truncate"
                                            )}
                                          >
                                            {content.title}
                                          </span>

                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active
                                                  ? "text-white"
                                                  : "text-indigo-600",
                                                "absolute inset-y-0 right-0 flex items-center pr-4"
                                              )}
                                            >
                                              <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>

                    {/* <ContentDropdown/> */}
                  </div>
                </div>
                <div>
                  <button
                    disabled={isSubmitting}
                    type="submit"
                    className="group relative z-0 w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md transition text-brandblue bg-brandlightblue hover:bg-brandblue hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <DocumentSearchIcon
                        className="h-5 w-5 text-brandblue group-hover:text-white"
                        aria-hidden="true"
                      />
                    </span>
                    Submit URL
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully submitted!
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      We'll check this link and remove your infringed content if
                      possible.
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default Requests;
