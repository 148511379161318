import usersService from '../services/usersService';
import useSWR from 'swr';
import { IUser } from '../shared/interfaces';

const fetchWithToken = (url: string, token: string) => {
  return usersService.findUserById(url, token);
};

export const useUser = (id: string, token: string) => {
  const { data, error, mutate } = useSWR(
    id && token ? [`/user/findUserById/${id}`, token] : null,
    fetchWithToken
  );

  const checkUserInfo = (user: IUser) => {
    if(user && user.content && user.content.length && user.stripeSubscriptionPlan && user.stripeSubscriptionPlan !== 'no plan' && user.stripeSubscriptionPlan !== 'not valid')
      return true;
    return false;  
  }

  const checkIfUserHasPlan = (user: IUser) => {
    if(user.stripeSubscriptionPlan && user.stripeSubscriptionPlan !== 'no plan' && user.stripeSubscriptionPlan !== 'not valid') 
      return true;
    return false;
  }
  // console.log(id, token, data)
  return {
    currentUser: data as IUser,
    isLoadingData: !error && !data,
    isError: error,
    mutate,
    checkUserInfo,
    checkIfUserHasPlan
  };
};
