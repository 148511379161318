import { FC } from 'react';
import { ComponentType, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';


let auth0_audience: string = process.env.REACT_APP_AUTH0_AUDIENCE as string;


export interface WithRoleBasedRedirectOptions {
    role: string;
}

const roleClaimType = `${auth0_audience}/roles`;


export const withRoleBasedRedirect = <P extends object>(
        Component: ComponentType<P>,
        options: WithRoleBasedRedirectOptions
    ): FC<P> => (props: P): JSX.Element => {

    const history = useHistory();
    const { getIdTokenClaims } = useAuth0();
    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        
        async function getRoles(): Promise<Array<string>> {
            const claims = await getIdTokenClaims();
            // console.log('### WRBR #### Claims:', claims, roleClaimType,  claims[roleClaimType])
            return claims[roleClaimType] || [];
        }

        async function checkRoles(role: string) {
            const roles = await getRoles();
            const isAuthorized = roles.includes(role);

            if (!isAuthorized) {
                history.push('/home');
            } else {
                setIsAuthorized(true);
            }
        }

        checkRoles(options.role);

    }, [getIdTokenClaims, history]);

    return isAuthorized ? <Component {...props} /> : <div></div>;
};