import { FC, useContext } from "react";
import { TrackedIcon, PendingIcon, ReportsIcon} from '../../icons/Icons';
import { UserIdContext } from "../../../contexts/UserIdContext";
import { useUserStats } from "../../../swrHooks/useUserStats";


const StatCards: FC<{}> = () => {
  const { id, userToken } = useContext(UserIdContext);
  const { userStats } = useUserStats(id as string, userToken as string);

  const cards = [
    {
      name: "Infringing URLs",
      icon: TrackedIcon,
      stat: userStats ? userStats.totalUrlsTracked : "Calculating",
    },
    {
      name: "Pending DMCA Takedowns",
      icon: PendingIcon,
      stat: userStats ? userStats.totalTakedownsPending : "Calculating",
    },
    {
      name: "Successful Takedowns",
      icon: ReportsIcon,
      stat: userStats ? userStats.totalTakedownsCompleted : "Calculating",
    },
  ];

  const StatCard: FC<{name: string, Icon: () => JSX.Element, stat: number | string}> = ({name, Icon, stat}) => {
    return (
      <div className="w-full lg:w-1/3 p-4">
             <div className="p-6 rounded bg-white shadow-lg">
               <div className="flex mb-2">
                 <Icon />
                 <h3 className="text-sm text-gray-600">{name}</h3>
                 <span className="inline-block ml-auto px-2 py-1 text-xs text-gray-500 bg-gray-50 rounded-full">
                   Total
                 </span>
               </div>
               <h2 className="mb-2 text-3xl font-bold">{stat}</h2>
             </div>
     </div>
    );
  }

  return (
    <section className="py-8">
      <div className="container mx-auto">
        <div className="flex flex-wrap -m-4">
          {cards.map((card, ind) =>  
              <StatCard  key={ind} name={card.name} Icon={card.icon} stat={card.stat}/>
          )}
        </div>
      </div>
    </section>
  );
};

export default StatCards;
