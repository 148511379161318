import { Fragment, useContext, useRef, useState, FC } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { BadgeCheckIcon } from '@heroicons/react/outline';
import { VerifyContentContext } from '../../contexts/VerifyContentModalOpen';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';

interface IProps {
  addVerificationRequest: () => {},
  VerificationUrl: string,
  VerificationCode: string,
  VerificationStatus: boolean,
}

const VerifyContentModal: FC<IProps> = ({addVerificationRequest,VerificationUrl,VerificationCode,VerificationStatus}) => {
  const [open, setOpen] = useState(true);
  const { openVerifyContentModal, setOpenVerifyContentModal } =
    useContext(VerifyContentContext);

  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={openVerifyContentModal} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-10 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
              <div className='sm:flex sm:items-start'>
                <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10'>
                  <BadgeCheckIcon
                    className='h-6 w-6 text-green-600'
                    aria-hidden='true'
                  />
                </div>
                <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg leading-6 font-medium text-gray-900'
                  >
                    <i>Content Verification Code:</i> <span style={{userSelect: 'all', cursor: 'pointer'}}>{VerificationCode}</span>
                  </Dialog.Title>
                  <div className='mt-2'>
                    <p className='text-sm text-gray-500'>
                      There are two options to verify your content ownership. <br />You can:<br />1. Copy & paste the Content Verification Code anywhere on your Content page. <br />OR<br />2. Create a html file containing the code on <br /><u>{VerificationUrl}</u>
                    </p>
                  </div>
                </div>
              </div>
              <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
              <a href="https://help.harvel.io/support/kb/articles/L9P5MnWJ/content-verification" target="_blank" rel="noreferrer noopener"><QuestionMarkCircleIcon
                    className='h-6 w-6 text-gray-600 mt-1 ml-2'
                    aria-hidden='true'
                  />
                  </a>
                <button
                  type='button'
                  className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm'
                  onClick={() => setOpenVerifyContentModal(false)}
                  ref={cancelButtonRef}
                >
                  Close
                </button>
                <button
                  type='button'
                  className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mr-3 sm:w-auto sm:text-sm'
                  onClick={() => addVerificationRequest()}
                >
                  Verify Now
                </button>
                <div className='mr-6 mt-1'>
                {VerificationStatus?"Verification in progress":""}
                </div>
                {/* todo: add verification label status here */}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default VerifyContentModal;
