import { FC, useContext } from 'react'
import { SelectedUserIdContext } from '../../../../contexts/SelectedUserIdContext';
import { IQuickDashNewContent, IUserContent } from '../../../../shared/interfaces';

const AdminQuickNewTableResult: FC<{result: IQuickDashNewContent, ind: number, updateSelectedContent: (newContent: IUserContent | null) => void}> = ({result, ind, updateSelectedContent }) => {

    
    const { selectedUserId, setSelectedUserId } = useContext(SelectedUserIdContext);

    return (
        <>
        <tr key={result._id} className={ind % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 break-all">
                {ind + 1}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-indigo-600 break-all">
                {result.user.displayName}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-indigo-600 break-all">
                {result.title}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-indigo-600 break-all">
                {new Date(result.createdAt!).toLocaleString() }
            </td>
            
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 break-all">
                <button
                    type="button"
                    className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs rounded text-indigo-600 hover:text-indigo-900 bg-indigo-100 hover:bg-indigo-200  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    onClick={() => {
                        if(selectedUserId !== result.user._id) {
                            setSelectedUserId(result.user._id);
                        }
                        updateSelectedContent(result)
                    }}
                    > Explore </button>
            </td>
        </tr>
        </>
    );
}
                        

export default AdminQuickNewTableResult;