import React, { createRef, FC } from "react";
import { createPopper } from "@popperjs/core";

interface ITooltipProps {
  tooltipdesc: string;
}

const Tooltip: FC<ITooltipProps> = ({ tooltipdesc }) => {
  const [tooltipShow, setTooltipShow] = React.useState(false);
  const btnRef = createRef<HTMLButtonElement>();
  const tooltipRef = createRef<HTMLDivElement>();
  const openLeftTooltip = () => {
    createPopper(btnRef.current as Element, tooltipRef.current as HTMLElement, {
      placement: "bottom",
    });
    setTooltipShow(true);
  };
  const closeLeftTooltip = () => {
    setTooltipShow(false);
  };
  return (
    <>
      <div className="inline">
        <div className="inline ml-2">
          <button
            className={
              "bg-gray-300 text-white active:bg-red-100 font-bold uppercase text-xs px-2 py-1 rounded-xl hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
            }
            type="button"
            onMouseEnter={openLeftTooltip}
            onMouseLeave={closeLeftTooltip}
            ref={btnRef}
          >
            ?
          </button>
          <div
            className={
              (tooltipShow ? "" : "hidden ") +
              "bg-gray-600 border-0 mt-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"
            }
            ref={tooltipRef}
          >
            <div>
              <div className="text-white p-3">{tooltipdesc}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tooltip;
