import { useEffect, useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "../home/home";
import Settings from "../settings/Settings";
import Navbar from "../navbar/navbar";
import Requests from "../requests/Requests";
import Content from "../content/Content";
import AdminDashboard from "../admin/adminDashboard";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { UserIdContext } from "../../contexts/UserIdContext";
import UsersService from "../../services/usersService";
import { IUser } from "../../shared/interfaces";
import StripeSuccess from "../stripe/StripeSuccess";
import StripeFailure from "../stripe/StripeFailure";
import HowItWorks from "../howItWorks/HowItWorks";
import AppSumo from "../appsumo/appsumo";
import Filters from "../Filters/Filters";
import { WithServerDataChecked } from "../hoc/WithServerDataChecked";
import Loading from "../loading/loading";
import UpdateSubscription from "../updateSubsccription/UpdateSubscription";


const Layout = () => {
  const { id, setId, setUserToken } = useContext(UserIdContext);

  const { getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    console.log('LOADING AUTH0 CALL');
    fetchToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  

  useEffect(() => {
    console.log('LOADS LAYOUT');
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchToken() {
    // console.log('---------- ID: ', id);
    if (!id) {
      const token = await getAccessTokenSilently();
      // setUserToken(token);
      // console.log('--- se stava token setUserToken() - context')
      if (user) {
        let newUser: IUser = {
          oauthId: user.sub as string,
          email: user.email as string,
          displayName: user.name as string,
          content: [],
        };

        // if the user is logged in with google acc
        if (user.given_name) newUser.firstName = user.given_name;
        if (user.family_name) newUser.lastName = user.family_name;
        if (user.picture) newUser.image = user.picture;

        UsersService.createNewUser(newUser, token).then((resp) => {
          // console.log('------- povik kon back')
          setId(resp.data);
          // console.log('--- se stava id setId() - context')
          setUserToken(token);
          // console.log('--- se stava token setUserToken() - context')
          // console.log(token);
        });
      }
    }

    // console.log('SE DESAVA')
  }

  let routes: JSX.Element = (
    <Switch>
      <WithServerDataChecked path="/home" component={Home} />
      <WithServerDataChecked path="/settings" component={Settings} />
      <WithServerDataChecked path="/requests" component={Requests} />
      <WithServerDataChecked path="/content" component={Content} />
      <WithServerDataChecked path="/whitelist" component={Filters} />
      <WithServerDataChecked path="/update-subscription" component={UpdateSubscription} />
      <Route path="/help" component={HowItWorks} exact />
      <Route path="/appsumo" component={AppSumo} exact />
      <Route path="/adminDashboard" component={AdminDashboard} exact />

      <Route path="/stripe-success" component={StripeSuccess} />
      <Route path="/stripe-cancel" component={StripeFailure} />

      <Redirect to="/home" />
    </Switch>
  );

  return (
        <div className='h-screen flex-1 overflow-y-auto'>
          <Navbar />
          <main className="mx-auto lg:ml-80 px-4 bg-gray-50 py-2 relative z-0 overflow-y-auto flex-1 h-screen">
            {routes}
          </main>
        </div>
  );
};

export default withAuthenticationRequired(Layout, {
  onRedirecting: () => <Loading />,
});
