import axios, { isAxiosError } from '../axios/axios-config';
import { IUser } from '../shared/interfaces';

const UsersService = {
  createNewUser: (user: IUser, token: string) => {
    return axios.post('/user/createNewUser', user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  // FIXME: Unused
  findAllUsers: (pageNumber: number) => {
    return axios.get(`/user/findAllUsers?pageNum=${pageNumber}`);
  },

  findUserById: async (url: string, token: string) => {
    const result = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log(result.data);
    return result.data;
  },

  calculateUserStats: async (url: string, token: string) => {
    const result = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  },

  addFilterToUser: async(userId: string, token: string, filter: string) => {
    try {
    const result = await axios.post(`/user/addFilterToUser/${userId}`, {
      filter
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return result.status; 
  }
    catch (err) {
      if(isAxiosError(err)) {
            return err.response?.data;
        }
    }
  },

  removeFilterFromUser: async(userId: string, token: string, filter:string) => {
    try {
    const result = await axios.patch(`/user/removeFilterFromUser/${userId}`, {
      filter
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    // console.log(result);
    return result.status; 
  }
    catch(err) {
      if(isAxiosError(err)) {
            return err.response?.data;
        }
    }
  }
};

export default UsersService;
