import { createContext } from 'react';

export type SelectedResultsIds = {
    selectedResultsIds: string[],
    setSelectedResultsIds: (ids: string[]) => void
}

export const SelectedResultsIdsContext = createContext<SelectedResultsIds>({
    selectedResultsIds: [],
    setSelectedResultsIds: () => {},
}) 