import { FC, useState } from 'react';
import { AdminDashQuickMode } from '../../../core/constants';
import { IUserContent } from '../../../shared/interfaces';
import AdminDashSwitch from './AdminDashSwitch';
import AdminDashNew from './newContent/AdminQuickNewContent';
import AdminDashOutstanding from './outstandingRetrievedReults/AdminQuickOutstandingContent';

const AdminDashView: FC<{updateSelectedContent: (newContent: IUserContent | null) => void}> = ({updateSelectedContent}) => {

    const [viewMode, setViewMode] = useState<AdminDashQuickMode>(AdminDashQuickMode.NewContent);

    const updateViewMode = (mode: AdminDashQuickMode) => {
        setViewMode(mode);
    }

    return (
        <>
            <AdminDashSwitch updateViewMode={updateViewMode} viewMode={viewMode} />
            {viewMode === AdminDashQuickMode.OutstandingLinks && <AdminDashOutstanding />}
            {viewMode === AdminDashQuickMode.NewContent && <AdminDashNew updateSelectedContent={updateSelectedContent} /> }
        </>  
    );
};

export default AdminDashView