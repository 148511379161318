import BlogHeader from "./BlogHeader";
import BlogPost from "./BlogPost";
import AddingFirstContent from "../../shared/images/adding-first-content.jpg";
import img2blog from "../../shared/images/img2blog.jpg";
import img3blog from "../../shared/images/img3blog.png";
import img4blog from "../../shared/images/img4blog.jpg";
import img5blog from "../../shared/images/ezgif.com-gif-maker (1).jpg";

export const Blog = () => {
  return (
    <>
      <div className="py-10 bg-gray-50 radius-for-skewed">
        <div className="container mx-auto px-4">
          <div className="mb-6 flex flex-wrap justify-center">
            <BlogHeader/>
            <div className="flex flex-wrap -mx-3 mb-16">
              <div className="mb-6 lg:mb-0 w-full lg:w-1/2 px-3 lg:pb-6">
                <div className="h-full flex flex-col rounded shadow">
                  <img
                    className="rounded-t object-cover h-80 lg:h-full w-full"
                    src={img5blog}
                    alt=""
                  />
                  <div className="mt-auto p-6 rounded-b bg-white">
                    <span className="text-sm text-gray-400">
                      Creator Know-How
                    </span>
                    <h2 className="my-2 text-2xl font-bold">
                      Earn 30% lifetime commission now
                    </h2>
                    <p className="mb-6 text-gray-400 leading-loose">
                    Earn money with our affiliate program. We offer 30% commissions to everyone who referred a sing-up for a monthly subscription.
                    Start earning passive income today!
                    </p>
                    <a
                      className="text-brandcolor hover:text-brandblue font-bold"
                      href="https://harvel.io/affiliates"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap w-full lg:w-1/2">
                <BlogPost  
                image={AddingFirstContent}
                label="Creator Know-How"
                title="As a creator, is my content automatically copyrighted?"
                link="https://harvel.io/post/is-my-content-automatically-copyrighted"
                />
                <BlogPost  
                image={img3blog}
                label="Creator Know-How"
                title="Pirates Impact on Creator Economy?"
                link="https://harvel.io/post/pirates-impact-on-creator-economy"
                />
                <BlogPost  
                image={img4blog}
                label="Help Center"
                title="Infringement Status - explained"
                link="https://help.harvel.io/support/kb/articles/dQGez0W4/infringement-status"
                />
                {/* <BlogPost  
                image={img5blog}
                label="Earn money with our affiliate program. We offer 30% commissions to everyone who referred a sing-up for a monthly subscription."
                title="Earn 30% lifetime commission now"
                link="https://harvel.io/affiliates"
                /> */}
                <BlogPost  
                image={img2blog}
                label="Creator Know-How"
                title="As a creator, is my content automatically copyrighted?"
                link="https://harvel.io/post/adding-your-content"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
