const Socials = () => {
  return (
    <>
      <div className=" flex flex-col text-center">
        <span className="text-lg font-bold text-gray-900 mb-2">
          Stay in the loop
        </span>
        <div className="flex items-center justify-center mt-1 space-x-3">
          <a
            href="https://twitter.com/harvel_hq"
            target="_blank"
            rel="noreferrer noopener"
            className="text-gray-500 transition-colors duration-300 hover:text-brandcolor"
          >
            <svg viewBox="0 0 24 24" fill="currentColor" className="h-8 text">
              <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
            </svg>
          </a>
          <a
            href="https://instagram.com/harvel_hq"
            target="_blank"
            rel="noreferrer noopener"
            className="text-gray-500 transition-colors duration-300 hover:text-brandcolor"
          >
            <svg viewBox="0 0 30 30" fill="currentColor" className="h-8">
              <circle cx="15" cy="15" r="4" />
              <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
            </svg>
          </a>
          <a
            href="https://tiktok.com/@harvel_hq"
            target="_blank"
            rel="noreferrer noopener"
            className="text-gray-500 transition-colors duration-300 hover:text-brandcolor"
          >
            <svg
              viewBox="0 0 2859 3333"
              fill="currentColor"
              className="h-6"
              shapeRendering="geometricPrecision"
              textRendering="geometricPrecision"
              imageRendering="optimizeQuality"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z" />
            </svg>
          </a>
        </div>
        <p className="mt-4 text-sm text-gray-500">
          &copy; Harvel, Inc. 2022. All Rights Reserved.
        </p>
        <p className="mt-24 text-xs text-gray-500">
          {" "}
          You're curious, I like you 😊 &nbsp; Leave a review on our Twitter and
          get 20% OFF second month!
        </p>
      </div>
    </>
  );
};

export default Socials;
