import { FC, useContext, useEffect, useState } from 'react';
import { AdminQuickLoadingContext } from '../../../../contexts/AdminQuickLoadingContext';
import { AdminQuickSearchContext } from '../../../../contexts/AdminQuickSearchContext';
import { UserIdContext } from '../../../../contexts/UserIdContext';
import { QuickModePageSize } from '../../../../core/constants';
import { getNewUserContent } from '../../../../services/adminService';
import { IQuickDashNewContent, IQuickUser, IUserContent } from '../../../../shared/interfaces';
import { useUser } from '../../../../swrHooks/useUser';
import Loading from '../../../loading/loading';
import Pager from '../../../pager/pager';
import AdminQuickNewTable from './AdminQuickNewTable';

const AdminQuickNewContent: FC<{
  updateSelectedContent: (newContent: IUserContent | null) => void}> = ({updateSelectedContent}) => {

  const { id, userToken } = useContext(UserIdContext);
  const { currentUser, isLoadingData } = useUser(
    id as string,
    userToken as string
  );
  const { setIsLoading } = useContext(AdminQuickLoadingContext);
  const [nextPageToken, setNextPageToken] = useState<string | undefined>();
  const [page, setPage] = useState<number>(1);
  const [maxPage, setMaxPage] = useState<number>(1);
  const [hasMoreEntries, setHasMoreEntries] = useState<boolean>(true);
  const [entries, setEntries] = useState<IQuickDashNewContent[]>([]);
  const [searchKeysMap, setSeachKeysMap] = useState<Map<string, string>>(new Map([['userName', ''], ['contentName', '']]));

  const updateSearchKeysMap = (key: string, value: string) => {
    let newMap = new Map(searchKeysMap);
    newMap.set(key, value);
    setSeachKeysMap(newMap);
    setPage(1)
    setNextPageToken('')
    setMaxPage(1)
    setIsLoading(true)
    setEntries([] as IQuickDashNewContent[])
    getFirstPage(newMap)
  }
  
  const setPageNumber = async (newPage: number) => {
    if(newPage > maxPage) {
      setIsLoading(true);
      const result = await getNewUserContent(
        userToken as string,
        nextPageToken,
        searchKeysMap.get('userName'),
        searchKeysMap.get('contentName'));
      let { pageToken, users } = result;
      setMaxPage(m => m + 1)
      setNextPageToken(pageToken);
      let newContentEntries: IQuickDashNewContent[] = []
      if (users) {
        for (let user of users as IQuickUser[]) {
          for(let content of user.content as IQuickDashNewContent[]) {
            newContentEntries.push({
              ...content,
              user: user,
            })
          }
        }
      }
      setEntries(entries => [...entries, ...newContentEntries]);
      if(newContentEntries.length < QuickModePageSize)
        setHasMoreEntries(false)
      setIsLoading(false)
    }
    setPage(newPage);
  }

  const getFirstPage = (searchParams?: Map<string, string>) => {
    const getInitialPage = async () => {
      setIsLoading(true)
      return await getNewUserContent(
        userToken as string,
        undefined,
        searchParams?.get('userName'),
        searchParams?.get('contentName'));
    }

    if(currentUser){
      getInitialPage().then((rez) => {
        let { pageToken, users } = rez;
        setNextPageToken(pageToken)
        
        let newContentEntries: IQuickDashNewContent[] = []
        if(users) {
          for (let user of users as IQuickUser[]) {
            for(let content of user.content as IQuickDashNewContent[]) {
              newContentEntries.push({
                ...content,
                user: user,
              })
            }
          }
        }
        setEntries(newContentEntries);
        if(newContentEntries.length < QuickModePageSize)
          setHasMoreEntries(false)
        setIsLoading(false)
      })
    }
  }
  
  useEffect(() => {
    getFirstPage()
  // eslint-disable-next-line
  }, [currentUser, userToken]);

  if(isLoadingData) {
    return <Loading/>;
  }

  const pageEntries: IQuickDashNewContent[] = entries.slice((page - 1) * QuickModePageSize, page * QuickModePageSize);

  return (
     <>
      <div className="mt-1 pb-5 border-b border-gray-200 items-center 
                      grid grid-cols-1 gap-5 sm:grid-cols-2 ">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            New Content
          </h3>
        </div>
      </div>
      <Pager page={page} setPage={setPageNumber} canLoadMore={hasMoreEntries}/>
      <div className="mt-3">
        <AdminQuickSearchContext.Provider value={{searchKeysMap, updateSearchKeysMap}} >
          <AdminQuickNewTable entries={pageEntries} page={page} updateSelectedContent={updateSelectedContent} />
        </AdminQuickSearchContext.Provider>
      </div>
      </>  
  );
};

export default AdminQuickNewContent