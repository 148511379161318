import { FC, useContext } from 'react';
import { IUserContent } from '../../shared/interfaces';
import AdminDashTableResult from './AdminDashTableResult';
import AdminDashTableHead from './AdminDashTableHead';
import { SelectedResultsIdsContext } from '../../contexts/SelectedResultsIdsContext';
import { AdminQuickLoadingContext } from '../../contexts/AdminQuickLoadingContext';
import Loading from '../loading/loading';

const AdminDashTable: FC<{
  selectedContent: IUserContent | null,
  showall: boolean
}> = ({ selectedContent, showall }) => {

    const {setSelectedResultsIds} = useContext(SelectedResultsIdsContext);
    const {isLoading} = useContext(AdminQuickLoadingContext);

    const selectAllOnCurrentPage = (selection: boolean) => {
      if(selection && selectedContent){
        setSelectedResultsIds(selectedContent.retrievedResults.map(result => result._id!));
      } else {
        setSelectedResultsIds([] as string[]);
      }
    }

    return (
        <div> 
            <div className="flex flex-col mt-6">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow border-b overflow-visible border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <AdminDashTableHead selectAllOnCurrentPage={selectAllOnCurrentPage} />
                      <tbody>
                        {!showall && !isLoading && selectedContent!.retrievedResults.filter(res => res.infringementStatus !== 'No Infringement').map((result, Idx) => (
                          <AdminDashTableResult result={result} ind={Idx} userContentId={selectedContent!._id} key={Idx}/>
                        ))}
                        {showall && !isLoading && selectedContent!.retrievedResults.map((result, Idx) => (
                          <AdminDashTableResult result={result} ind={Idx} userContentId={selectedContent!._id} key={Idx}/>
                        ))}
                      </tbody>
                    </table>
                    {isLoading && <Loading minimised={true} />}
                  </div>
                </div>
              </div>
            </div>
        </div>
    )

}

export default AdminDashTable;