import { FC, useState } from "react";
import NavItem from './NavItem';
import { HomeIcon, ContentIcon, RequestsIcon, WhitelistIcon, HelpIcon, HowItWorksIcon, SettingsIcon, LogOutIcon, MobileMenuIcon } from '../icons/Icons';
import { useAuth0 } from "@auth0/auth0-react";
import { ExternalLinkIcon } from '@heroicons/react/outline'
import harvelBlue from "../../shared/images/harvel-logo-blue.png";


function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Navbar: FC<{}> = () => {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const { logout } = useAuth0();


  const openSidebarMenu = () => {
    setToggleSidebar(!toggleSidebar);
  }

  return (
    <div>
      <nav className="lg:hidden py-6 px-6 border-b">
        <div className="flex items-center justify-between">
            <img
              src={harvelBlue}
              alt="Harvel"
              width="150"
            />
          <button 
          className="navbar-burger flex items-center rounded focus:outline-none"
          onClick={openSidebarMenu}
          >
            <MobileMenuIcon />
          </button>
        </div>
      </nav>
      <div className={classNames(toggleSidebar ? 'visible opacity-1 relative' : 'invisible opacity-0 lg:visible lg:opacity-100 ', 'transition duration-500 ease-in-out lg:block navbar-menu z-50 ')}>
        <div 
        className="navbar-backdrop fixed lg:hidden inset-0 bg-gray-800 opacity-10"
        onClick={openSidebarMenu}
        >
        </div>
        <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-3/4 lg:w-80 sm:max-w-xs pt-6 pb-8 bg-white border-r ">
          <div className="flex w-full items-center px-6 pb-6 mb-6 lg:border-b border-blue-50">
              <img
                src={harvelBlue}
                alt="Harvel"
                width="150"
              />
          </div>
          <div className="px-4 pb-6">
            <h3 className="mb-2 text-xs uppercase text-gray-500 font-medium">
              Dashboard
            </h3>
            <ul className="mb-8 text-sm font-medium">
              <li>
                <NavItem  name='Home' path='/home' Icon={HomeIcon} setToggleSidebar={setToggleSidebar}/>
              </li>
              <li>
                <NavItem  name='Content' path='/content' Icon={ContentIcon} setToggleSidebar={setToggleSidebar}/>
              </li>
              <li>
                <NavItem  name='Requests' path='/requests' Icon={RequestsIcon} setToggleSidebar={setToggleSidebar}/>
              </li>
              <li>
                <NavItem  name='Whitelist' path='/whitelist' Icon={WhitelistIcon} setToggleSidebar={setToggleSidebar}/>
              </li>
            </ul>
            <h3 className="mb-2 text-xs uppercase text-gray-500 font-medium">
              HELP
            </h3>
            <ul className="text-sm font-medium">
              <li>
              <a
                className="flex items-center pl-3 py-3 pr-2 text-brandblue hover:bg-brandthinblue rounded"
                href="https://help.harvel.io/support/kb/categories"
                target='_blank'
                rel='noreferrer noopener'
              >
                <HelpIcon />
                <span>Help</span>
                <ExternalLinkIcon className='inline-block ml-auto w-3.5 h-3.5 text-gray-400'/>
              </a>
              </li>
              <li>
              <NavItem  name='How It Works' path='/help' Icon={HowItWorksIcon} setToggleSidebar={setToggleSidebar}/>
              </li>
            </ul>
            <div className="pt-8">
            <NavItem  name='Settings' path='/settings' Icon={SettingsIcon} setToggleSidebar={setToggleSidebar}/>
            <button
                className="flex items-center pl-3 py-3 pr-4 w-full text-brandblue font-medium text-sm hover:bg-brandthinblue rounded"
                onClick={() =>
                  logout({
                    returnTo: "https://harvel.io",
                  })
                }
              >
                <LogOutIcon />
                <span>Logout</span>
              </button>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
