import { createContext } from 'react';

export type DialogOpen = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const DialogOpenContext = createContext<DialogOpen>({
  open: false,
  setOpen: () => {},
});
