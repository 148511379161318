import { FC, useContext } from 'react';
import { CashIcon } from '@heroicons/react/solid';
import { useUser } from '../../swrHooks/useUser';
import { UserIdContext } from '../../contexts/UserIdContext';
import { openCustomerPortal } from '../../services/stripeService';

// TODO: onClick function should redirect to the customer portal
const StripePortal: FC<{}> = () => {
  const { id, userToken } = useContext(UserIdContext);
  const { currentUser } = useUser(id as string, userToken as string);

  const openPortalClick = async () => {
    // console.log(currentUser.stripeCustomerId);

    if (currentUser.stripeCustomerId && (currentUser.stripeCustomerId.includes("appsumo") === false)) {
      const resp = await openCustomerPortal(
        currentUser.stripeCustomerId,
        userToken as string
      );
      window.location.href = resp.url;
    } else {
      // console.log();
    }
  };

  return (
    <button
      type='button'
      className='inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
      onClick={() => openPortalClick()}
    >
      Go to Stripe Portal
      <CashIcon className='ml-2 -mr-0.5 h-4 w-4' aria-hidden='true' />
    </button>
  );
};

export default StripePortal;
