import { createContext } from 'react';

export type UserContentInfo = {
  userContentTitle: string | null;
  setUserContentTitle: (contentId: string) => void;
  userContentUrl: string | null;
  setUserContentUrl: (contentId: string) => void;
};

export const UserContentInfoContext = createContext<UserContentInfo>({
  userContentTitle: null,
  setUserContentTitle: () => {},
  userContentUrl: null,
  setUserContentUrl: () => {},
});
