import { FC, useContext, useEffect, useState } from 'react';
import { AdminQuickLoadingContext } from '../../../../contexts/AdminQuickLoadingContext';
import { AdminQuickSearchContext } from '../../../../contexts/AdminQuickSearchContext';
import { SelectedQuickResultsIdsContext } from '../../../../contexts/SelectedQuickResultsIdsContext';
import { UserIdContext } from '../../../../contexts/UserIdContext';
import { QuickModePageSize } from '../../../../core/constants';
import { getOutstandingUserContent } from '../../../../services/adminService';
import { IQuickDashNewContent, IQuickDashRetrievedResults, IQuickUser } from '../../../../shared/interfaces';
import { useUser } from '../../../../swrHooks/useUser';
import Loading from '../../../loading/loading';
import Pager from '../../../pager/pager';
import AdminDashButtons from '../../AdminDashButtons';
import AdminQuickOutstandingTable from './AdminQuickOutstandingTable';

const AdminDashOutstanding: FC<{}> = ()  => {

  const { id, userToken } = useContext(UserIdContext);
  const { currentUser, isLoadingData } = useUser(
    id as string,
    userToken as string
  );
  const { setIsLoading } = useContext(AdminQuickLoadingContext);
  const [nextPageToken, setNextPageToken] = useState<string | undefined>();
  const [oldPageToken, setOldPageToken] = useState<string | undefined>();
  const [page, setPage] = useState<number>(1);
  const [maxPage, setMaxPage] = useState<number>(1);
  const [hasMoreEntries, setHasMoreEntries] = useState<boolean>(true);
  const [entries, setEntries] = useState<IQuickDashRetrievedResults[]>([]);
  const [selectedQuickResultsMap, setSelectedQuickResultsMap] = useState<Map<string, string[]>>((new Map()) as Map<string, string[]>);
  const [searchKeysMap, setSeachKeysMap] = useState<Map<string, string>>(new Map([['userName', ''], ['contentName', '']]));


  const updateSearchKeysMap = (key: string, value: string) => {
    let newMap = new Map(searchKeysMap);
    newMap.set(key, value);
    setSeachKeysMap(newMap);
    setSelectedQuickResultsMap(new Map() as Map<string, string[]>);
    setPage(1)
    setNextPageToken('')
    setMaxPage(1)
    setIsLoading(true)
    setEntries([] as IQuickDashRetrievedResults[])
    getFirstPage(newMap)
  }

  const getFirstPage = (searchParams?: Map<string, string>) => {
    const getInitialPage = async () => {
      setIsLoading(true)
      return await getOutstandingUserContent(
        userToken as string,
        undefined,
        searchParams?.get('userName'),
        searchParams?.get('contentName'),
      );
    }

    if(currentUser){
      getInitialPage().then((rez) => {
        
        let { pageToken, users } = rez;
        setNextPageToken(pageToken)
        
        let newEntries: IQuickDashRetrievedResults[] = []
        if(users) {
          for (let user of users as IQuickUser[]) {
            for (let content of user.content as IQuickDashNewContent[]) {
              for (let result of content.retrievedResults as IQuickDashRetrievedResults[]) {
                newEntries.push({
                  ...result,
                  content: content,
                  user: user,
                })
              }
            }
          }
        }
        setEntries(newEntries);
        if(newEntries.length < QuickModePageSize)
          setHasMoreEntries(false)
        setIsLoading(false)
      })
    }
  }

  const selectAllOnCurrentPage = (selection: boolean) => {
    const newMap = new Map() as Map<string, string[]>;
    if(selection) {
      entries.slice(page - 1, (page * QuickModePageSize)).forEach((entry) => {
        const keyExists = newMap.has(entry.content._id + '_' + entry.user._id);
        if (!keyExists) {
          newMap.set(entry.content._id + '_' + entry.user._id, [entry._id!]);
        }
        else {
          const currentValue = newMap.get(entry.content._id + '_' + entry.user._id);
          newMap.set(entry.content._id + '_' + entry.user._id, [...currentValue!, entry._id!]);
        }
      })
    }
    setSelectedQuickResultsMap(newMap);
  }

  const refreshCurrentPage = async () => {
    setIsLoading(true);
    const result = await getOutstandingUserContent(
      userToken as string,
      oldPageToken,
      searchKeysMap.get('userName'),
      searchKeysMap.get('contentName'),
    );
    let { users } = result;
    let updatedEntries: IQuickDashRetrievedResults[] = []
    if(users) {
      for (let user of users as IQuickUser[]) {
        for (let content of user.content as IQuickDashNewContent[]) {
          for (let result of content.retrievedResults as IQuickDashRetrievedResults[]) {
            updatedEntries.push({
              ...result,
              content: content,
              user: user,
            })
          }
        }
      }
    }
    updatedEntries = entries.slice(0, (page - 1) * QuickModePageSize).concat(updatedEntries);
    setEntries(updatedEntries);
    setIsLoading(false)
  }
  
  const setPageNumber = async (newPage: number) => {
    if(newPage > maxPage) {
      setOldPageToken(nextPageToken)
      setIsLoading(true);
      const result = await getOutstandingUserContent(
        userToken as string,
        nextPageToken,
        searchKeysMap.get('userName'),
        searchKeysMap.get('contentName'),
      );
      let { pageToken, users } = result;
      setMaxPage(m => m + 1)
      setNextPageToken(pageToken);
      let newEntries: IQuickDashRetrievedResults[] = []
      if(users) {
        for (let user of users as IQuickUser[]) {
          for (let content of user.content as IQuickDashNewContent[]) {
            for (let result of content.retrievedResults as IQuickDashRetrievedResults[]) {
              newEntries.push({
                ...result,
                content: content,
                user: user,
              })
            }
          }
        }
      }
      setEntries(entries => [...entries, ...newEntries]);
      if(newEntries.length < QuickModePageSize)
        setHasMoreEntries(false)
      setIsLoading(false)
    }
    setPage(newPage);
  }
  
  useEffect(() => {
    getFirstPage()
  // eslint-disable-next-line
  }, [currentUser, userToken]);

  if(isLoadingData) {
    return <Loading/>;
  }

  const pageEntries: IQuickDashRetrievedResults[] = entries.slice((page - 1) * QuickModePageSize, page * QuickModePageSize);

  return (
     <>
      <div className="mt-1 pb-5 border-b border-gray-200 items-center 
                      grid grid-cols-1 gap-5 sm:grid-cols-2 ">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Outstanding URLs
          </h3>
        </div>
      </div>
      <Pager page={page} setPage={setPageNumber} canLoadMore={hasMoreEntries}/>
      <div className="mt-3">
        <AdminQuickSearchContext.Provider value={{searchKeysMap, updateSearchKeysMap}} >
          <SelectedQuickResultsIdsContext.Provider value={{selectedQuickResultsMap, setSelectedQuickResultsMap}}>
            <AdminDashButtons isExtended={true} refreshCurrentPage={refreshCurrentPage} />
            <AdminQuickOutstandingTable entries={pageEntries} page={page} selectAllOnCurrentPage={selectAllOnCurrentPage} />
          </SelectedQuickResultsIdsContext.Provider>
        </AdminQuickSearchContext.Provider>
      </div>
      </>  
  );
};

export default AdminDashOutstanding