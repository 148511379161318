import React, { FC, useContext, useEffect, useState } from "react";
import { UserIdContext } from "../../contexts/UserIdContext";
import { useUser } from "../../swrHooks/useUser";
import { ExternalLinkIcon, PencilIcon, SaveIcon } from "@heroicons/react/solid";
import { Warning } from "../warning/Warning";
import { DialogOpenContext } from "../../contexts/DialogOpenContext";
import { DeleteContentContext } from "../../contexts/DeleteContentModalOpen";
import { VerifyContentContext } from "../../contexts/VerifyContentModalOpen";
import AddContentModal from "../modals/AddContentModal";
import DeleteContentModal from "./../modals/DeleteContentModal";
import VerifyContentModal from "./../modals/VerifyContentModal";
import { addContentVerificationRequest, changeUserContentStatus } from "../../services/userContentService";
import { IUserContent } from "../../shared/interfaces";
import ShowConfirmedUrlsModal from "./../modals/ShowConfirmedUrlsModal";
import { createPopper } from "@popperjs/core";
import StatCards from "./../home/elements/StatCards";
import { Link } from "react-router-dom";
import axios from "axios";
import { openCustomerPortal } from "../../services/stripeService";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Content: FC<{}> = () => {
  const { id, userToken } = useContext(UserIdContext);
  const { currentUser, mutate, checkUserInfo, checkIfUserHasPlan } = useUser(
    id as string,
    userToken as string
  );
  const [open, setOpen] = useState(false);
  const [openDeleteContentModal, setOpenDeleteContentModal] = useState(false);
  const [openVerifyContentModal, setOpenVerifyContentModal] = useState(false);
  const [VerificationCode, setVerificationCode] = useState<string>('');
  const [VerificationUrl, setVerificationUrl] = useState<string>('');
  const [VerificationStatus, setVerificationStatus] = useState(false);
  const [selectedContentId, setSelectedContentId] = useState("");
  const [viewUrls, setViewUrls] = useState(false);
  const [result, setResult] = useState<string[]>([]);

  const showConfirmationUrlsModal = (urls: string[]) => {
    if (urls.length > 0) {
      setResult(urls);
      setViewUrls(true);
    }
  };

  const popcorn = document.querySelector("#popcorn");
  const tooltip = document.querySelector("#tooltip");

  createPopper(popcorn as Element, tooltip as HTMLElement, {
    placement: "top",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8]
        }
      }
    ]
  }); // * TUKA KREARIS TOOLTIP VO FUNKCIJAVA
  // createPopper()

  const removeContentFromActiveContents = async () => {
    const res = await changeUserContentStatus(
      id as string,
      userToken as string,
      selectedContentId,
      false
    );
    if (res === 200) {
      let updatedContent = currentUser.content?.forEach((con) => {
        if (con._id === selectedContentId) con.active = false;
      });
      mutate({ ...currentUser, updatedContent }, false);
      setSelectedContentId("");
      setOpenDeleteContentModal(false);
    }
    // else {
    // }
  };

  const addVerificationRequest = async () => {
    const res = await addContentVerificationRequest(
      id as string,
      userToken as string,
      selectedContentId
    );
    if (res === 200) {
      setSelectedContentId("");
      // setOpenVerifyContentModal(false) //todo: change verification status label instead of closing modal
      setVerificationStatus(true)
    }
    // else {
    // }
  };
  
  // const verifyContent = async () => {
  //   // const res = await changeUserContentStatus(
  //   //   id as string,
  //   //   userToken as string,
  //   //   selectedContentId,
  //   //   false
  //   // );
  //   // if (res === 200) {
  //   //   let updatedContent = currentUser.content?.forEach((con) => {
  //   //     if (con._id === selectedContentId) con.active = false;
  //   //   });
  //   //   mutate({ ...currentUser, updatedContent }, false);
  //   //   setSelectedContentId("");
  //   //   setOpenDeleteContentModal(false);
  //   // }
  //   // else {
  //   // }
  // };

  const mutateContents = (title: string, url: string) => {
    const newContent: IUserContent = {
      title: title,
      url: url,
      _id: "1",
      active: true,
      retrievedResults: []
    };
    let updatedContents = currentUser.content?.push(newContent);
    mutate({ ...currentUser, updatedContents }, true);
  };


  let [doingContentTitleChange, setShowContentTitleChange] = React.useState(false);

  const doContentTitleChange = (value: string) => {
    setContentTitle(value);
    setShowContentTitleChange(true);
  };
  const [contentTitle, setContentTitle] = React.useState("");
  const [contentId, setContentId] = React.useState("");
  const onchangeSetContentTitle = (event: React.FormEvent<HTMLInputElement>) => {
    setContentTitle(event.currentTarget.value);
    setContentId(event.currentTarget.dataset.id as string);
  };
  const changeContentTitle = () => {
    let base = process.env.REACT_APP_SERVER_URL as string;
    axios.post(base + "/user/updateContentDetails/" + id + "/" + contentId, { "title": contentTitle }, {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    }).then((result) => {
      setShowContentTitleChange(false);
      window.location.reload();
    });
  };

  const [stripePortalUrl, setStripePortalUrl] = useState<string>('');

  useEffect(() => {
    const getStripeUrl = async () => {
      if (currentUser.stripeCustomerId && userToken && (currentUser.stripeCustomerId.includes("appsumo") === false)) {
        const resp = await openCustomerPortal(
          currentUser.stripeCustomerId,
          userToken as string
        );
        setStripePortalUrl(resp.url);
      }
    }
    getStripeUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // ? Question by brian: WHY DOES THE VerifyContentContext CONTAIN THE DELETE AND DIALOGOPEN providers? it seems not to work when I organize them in a logical order.
  return (
    <>
    
    <VerifyContentContext.Provider
      value={{ openVerifyContentModal, setOpenVerifyContentModal }}
    >
    <DeleteContentContext.Provider
      value={{ openDeleteContentModal, setOpenDeleteContentModal }}
    >
          
      <DialogOpenContext.Provider value={{ open, setOpen }}>
        {checkUserInfo(currentUser) ? (
          <>
            <div className="pb-5 border-b border-gray-200 mt-6">
              <div className="sm:flex sm:items-center sm:justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Your Contents
                </h3>
                <div className="mt-3 sm:mt-0 sm:ml-4">
                  <button
                    onClick={() => setOpen(true)}
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Add New Content
                  </button>
                </div>
              </div>
              <p className="mt-2 max-w-4xl text-sm text-gray-500">
                If you sell 1 piece of Content on multiple domains, just add 1 URL as Content, and Whitelist the
                additional URLs on the
                <Link to="/whitelist" className="pl-1 pr-1 text-blue-600">
                  whitelist
                </Link>
                page.
              </p>
            </div>
            <StatCards />

            {currentUser.stripeTrialPeriod && currentUser.stripeSubscriptionPlan !== 'free'
              && <div>
                <p className="text-sm text-brandcolor">You are currently on a trial plan with only 2 URL take-downs. Please upgrade <a className="text-brandblue" href={stripePortalUrl}>here</a> for unlimited URL take-downs.</p>
              </div>}

            {currentUser.stripeSubscriptionPlan === 'free'
              && <div>
                <p className="text-sm text-brandcolor">You are currently on a <b>Free Plan</b> with only 1 Content and 2 free Google Search DMCA take-downs per month. Please upgrade <a className="text-brandblue" href={"/update-subscription"}>here</a> for unlimited URL take-downs.</p>
              </div>}

            {currentUser!.content!.map((content) => (
              <div key={content._id} id={content._id}>
                <div
                  className={classNames(!content.active ? "hidden" : "", "mt-6 px-4 py-5 border-b border-gray-200 sm:px-6")}>
                  <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                    <div className="ml-4 mt-4">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">

                        <span className={classNames(doingContentTitleChange ? "hidden" : "")}>{content.title}
                          <PencilIcon width="24px" onClick={() => {
                            doContentTitleChange(content.title);
                          }} className="inline-block"></PencilIcon></span>
                        <span className={classNames(!doingContentTitleChange ? "hidden" : "", "inline-block")}><input
                          type="text" id="contentTitleElement" data-id={content._id} data-name={content.title}
                          value={contentTitle} className="inline-block"
                          onChange={(e) => onchangeSetContentTitle(e)}></input> <SaveIcon width="24px"
                            onClick={() => changeContentTitle()}
                            className="inline-block"></SaveIcon></span>

                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        {content.url}
                      </p>
                    </div>
                    {!content.title.includes("HOSTING SITES") ?
                      <div className="ml-4 mt-4 flex-shrink-0">
                        <button
                          onClick={() => {
                            console.warn('onclick registered')
                            setOpenVerifyContentModal(true);
                            if(content.verification_code && content.verification_url) {
                              setVerificationCode(content.verification_code)
                              setVerificationUrl(content.verification_url)
                              setVerificationStatus(content.verification_request?content.verification_request:false)
                            }
                            setSelectedContentId(content._id);
                          }}
                          disabled={!content.active}
                          type="button"
                          className={classNames(
                            content.verified
                              ? "hidden"
                              : "",
                            "relative inline-flex items-center px-3 py-1 mr-6 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-brandcolor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                          )}
                        >
                          Verify ownership
                        </button>
                        <span
                          className={classNames(
                            content.active
                              ? 
                              !content.verified ? "hidden":"bg-green-100 text-green-800"
                              : "bg-white text-red-300",
                              // : ,
                            "inline-flex items-center px-3 py-1 mr-6 rounded-full text-sm font-medium"
                          )}
                        >
                          {content.active ? "Active" : "Not Active"}
                        </span>
                        <button
                          onClick={() => {
                            setOpenDeleteContentModal(true);
                            setSelectedContentId(content._id);
                          }}
                          disabled={!content.active}
                          type="button"
                          className={classNames(
                            content.active
                              ? "cursor-pointer-"
                              : "cursor-not-allowed",
                            "relative inline-flex items-center px-3 py-1 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                          )}
                        >
                          Remove Content
                        </button>
                      </div> : <></>}
                  </div>
                </div>
                {content.retrievedResults.filter((content) => content.verified)
                  .length > 0 ? (
                  <div className="flex flex-col">
                    <div className="-my-0.5 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Url
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Infringement Status
                                </th>
                                <th scope="col" className="relative px-6 py-3">
                                  <span className="sr-only">View</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {content.retrievedResults
                                .filter((result) => result.verified)
                                .map((result) => (
                                  <tr key={result._id}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <div className="text-md text-gray-900">
                                        {result.url.length < 30
                                          ? result.url
                                          : result.url.substring(0, 30) + "..."}
                                      </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <span
                                        onClick={() => {
                                          showConfirmationUrlsModal(
                                            result.confirmationUrls as string[]
                                          );
                                        }}
                                        className={classNames(
                                          result.infringementStatus ===
                                            "Not yet handled"
                                            ? "bg-blue-50 text-blue-400"
                                            : result.infringementStatus ===
                                              "Pending"
                                              ? "bg-yellow-50 text-yellow-400"
                                              : result.infringementStatus ===
                                                "No Infringement"
                                                ? "bg-red-50 text-red-400"
                                                : result.confirmationUrls &&
                                                  result.confirmationUrls?.length >
                                                  0
                                                  ? "hover:bg-green-100 cursor-pointer bg-green-50 text-green-400"
                                                  : "bg-green-50 text-green-400",
                                          "px-3 inline-flex text-xs leading-5 font-semibold rounded-full select-none"
                                        )}
                                      >
                                        {result.confirmationUrls!.length > 0
                                          ? result.infringementStatus +
                                          " (VIEW)"
                                          : result.infringementStatus}
                                      </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                      <a
                                        href={result.url}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="text-gray-400 hover:text-indigo-900"
                                      >
                                        View URL
                                        <ExternalLinkIcon
                                          className="inline-block ml-1 h-4 w-4 text-gray-400"></ExternalLinkIcon>
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={classNames(!content.active ? "hidden" : "", "py-8 px-6")}>
                    <div className="p-6 bg-green-50 border-l-4 border-green-500 rounded-r-lg">
                      <div className="flex items-center mb-2">
                        <h3 className="text-green-800 font-medium">
                          Clean as a whistle! 🎉
                        </h3>
                        <button className="ml-auto"></button>
                      </div>
                      <div className="pr-6">
                        <p className="text-sm text-green-700">
                          If you JUST added this content, it takes 3-4 days for the first results to appear here
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <ShowConfirmedUrlsModal
                  confirmedUrls={result}
                  viewUrls={viewUrls}
                  setViewUrls={setViewUrls}
                />
                <AddContentModal mutateContents={mutateContents} />
                <DeleteContentModal
                  removeContentFromActiveContents={
                    removeContentFromActiveContents
                  }
                />
                <VerifyContentModal
                  addVerificationRequest={addVerificationRequest}
                  VerificationUrl={VerificationUrl}
                  VerificationCode={VerificationCode}
                  VerificationStatus={VerificationStatus}
                  />
              </div>
            ))}
          </>
        ) : checkIfUserHasPlan(currentUser) ?
          (<>
            <div className="pb-5 border-b mt-6 border-gray-200 sm:flex sm:items-center sm:justify-between">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Your Contents
              </h3>
              <div className="mt-3 sm:mt-0 sm:ml-4">
                <button
                  onClick={() => setOpen(true)}
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Add New Content
                </button>
              </div>
            </div>
            <AddContentModal mutateContents={mutateContents} />
          </>) :
          (<Warning
            message={
              "Please fill out the form in the home section first, in order to have your contents displayed here!"
            }
          />
          )}
      </DialogOpenContext.Provider>
    </DeleteContentContext.Provider>
    </VerifyContentContext.Provider>
    </>
  );
};

export default Content;
