import axios from '../axios/axios-config';
import { IUser } from '../shared/interfaces';
// nope
const appsumoService = {
    
  redeem: (user: IUser, coupon: string, token: string|null) => {
    return axios.post('/appsumo/redeem/'+coupon, user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
};

export default appsumoService;
