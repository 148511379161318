import { FC, useContext, useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../loading/loading';
import { withRoleBasedRedirect } from '../../auth/withRoleBasedRedirect';
import { UserIdContext } from '../../contexts/UserIdContext';
import { useUser } from '../../swrHooks/useUser';
import { IUserContent, IUserInfosAdminDash } from '../../shared/interfaces';
import AdminDashDropdowns from './adminDashDropdowns';
import { getUserInfos } from '../../services/adminService';
import AdminDashTable from './adminDashTable';
import { SelectedUserIdContext } from '../../contexts/SelectedUserIdContext';
import { ResultNotificationContext } from '../../contexts/ResultNotificationContext';
import AdminDashContentInfo from './AdminDashContentInfo';
import AdminDashTableResultNotification from './AdminDashTableResultNotification';
import AdminDashAddNewResult from './AdminDashAddNewResult';
import AdminDashButtons from './AdminDashButtons';
import { SelectedResultsIdsContext } from '../../contexts/SelectedResultsIdsContext';
import AdminDashUserStats from './AdminDashUserStats';
import AdminDashView from './quickDash/AdminDashView';
import { AdminQuickLoadingContext } from '../../contexts/AdminQuickLoadingContext';

import { Switch } from '@headlessui/react';

const AdminDashboard: FC<{}> = () => {

  const { id, userToken } = useContext(UserIdContext);
  const { currentUser, isLoadingData } = useUser(
    id as string,
    userToken as string
  );
  const [ userInfos, setUserInfos ] = useState<IUserInfosAdminDash[]>([])    // * props koi se davaat na adminDashDropdown za dropdownot za users
  const [ selectedContent, setSelectedContent ] = useState<IUserContent | null>(null);    // * content sto se izbira od dropdownot za contents od adminDashDropdown
  const [selectedUserId, setSelectedUserId] = useState('');
  const [notMessage, setNotMessage] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [selectedResultsIds, setSelectedResultsIds] = useState<string[]>([]);
  const [ isQuickLoading, setIsQuickLoading ] = useState<boolean>(true);
  const [showall, setShowAll] = useState<boolean>(false);
  
  const updateIsQuickLoading = (loading: boolean) => {
    setIsQuickLoading(loading);
  }

  useEffect(() => {
    if(currentUser){
      retrieveUserInfos().then((rez) => {
        setUserInfos(rez);
      })
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const retrieveUserInfos = async () => {
    return await getUserInfos(userToken as string);
  }

  const updateSelectedContent = (newContent: IUserContent | null) => {
    setSelectedContent(newContent);
  }


  if(isLoadingData) {
    return <Loading/>;
  }

  return (
     <SelectedUserIdContext.Provider value={{selectedUserId, setSelectedUserId}}>
     <ResultNotificationContext.Provider value={{notMessage, setNotMessage, success, setSuccess, showNotification, setShowNotification}} >
     <>
      <div className="mt-1 pb-5 border-b border-gray-200 items-center 
                      grid grid-cols-1 gap-5 sm:grid-cols-2 ">

        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Admin dashboard
          </h3>
        </div>

        <div>
          <AdminDashDropdowns userInfos={userInfos} selectedContent={selectedContent as IUserContent} updateSelectedContent={updateSelectedContent}/>
        </div>
      </div>
      <div className="mt-3">
        {selectedContent ? 
         <>
          <AdminDashUserStats userId={selectedUserId}/>
          <AdminDashContentInfo selectedContent={selectedContent} updateSelectedContent={updateSelectedContent} />
          <AdminDashAddNewResult selectedContent={selectedContent} updateSelectedContent={updateSelectedContent}/>

          <SelectedResultsIdsContext.Provider value={{selectedResultsIds, setSelectedResultsIds}}>
            <AdminDashButtons selectedContent={selectedContent} updateSelectedContent={updateSelectedContent}/>
            <Switch.Group>
            <Switch.Label className=" mt-3 block text-sm font-medium text-gray-700 ml-3">Show all</Switch.Label>
            <Switch
      checked={showall}
      onChange={setShowAll}
      className={`${
        showall ? 'bg-blue-600' : 'bg-gray-200'
      } relative inline-flex h-6 w-11 items-center rounded-full ml-3`}
    >
      <span className="sr-only">show all</span>
      <span
        className={`${
            showall ? 'translate-x-6' : 'translate-x-1'
        } inline-block h-4 w-4 transform rounded-full bg-white`}
      />
    </Switch>
    </Switch.Group>
            <AdminQuickLoadingContext.Provider value={{isLoading: false, setIsLoading: updateIsQuickLoading}}>
              <AdminDashTable  selectedContent={selectedContent} showall={showall} />
            </AdminQuickLoadingContext.Provider>
          </SelectedResultsIdsContext.Provider>
          </>
           : 
          <AdminQuickLoadingContext.Provider value={{isLoading: isQuickLoading, setIsLoading: updateIsQuickLoading}}>
            <AdminDashView updateSelectedContent={updateSelectedContent} />
          </AdminQuickLoadingContext.Provider  >
        }
      </div>
      <AdminDashTableResultNotification show={showNotification} setShow={setShowNotification} message={notMessage} success={success as boolean}/>                      
      </>
      </ResultNotificationContext.Provider>
      </SelectedUserIdContext.Provider>
  
  );
};

export default withRoleBasedRedirect( withAuthenticationRequired(AdminDashboard, { onRedirecting: () => <Loading />, }), { role: 'role-dashboardEditor'});