import { updateMultipleRetrievedResults } from "../../services/adminService";
import { useContext, FC } from 'react';
import { UserIdContext } from "../../contexts/UserIdContext";
import { SelectedUserIdContext } from "../../contexts/SelectedUserIdContext";
import { IUserContent } from "../../shared/interfaces";
import { SelectedResultsIdsContext } from "../../contexts/SelectedResultsIdsContext";
import { useState } from 'react';
import { SelectedQuickResultsIdsContext } from "../../contexts/SelectedQuickResultsIdsContext";
import { AdminQuickLoadingContext } from "../../contexts/AdminQuickLoadingContext";

const AdminDashButtons: FC<{
    selectedContent?: IUserContent | null,
    updateSelectedContent?: (newContent: IUserContent | null) => void,
    isExtended?: boolean,
    refreshCurrentPage?: () => void,
}> = ({ selectedContent, updateSelectedContent, isExtended, refreshCurrentPage }) => {

    const { setIsLoading } = useContext(AdminQuickLoadingContext)
    const [url, setUrl] = useState('');
    const { userToken } = useContext(UserIdContext);
    const { selectedUserId } = useContext(SelectedUserIdContext);
    const { selectedResultsIds, setSelectedResultsIds } = useContext(SelectedResultsIdsContext);
    const { selectedQuickResultsMap, setSelectedQuickResultsMap } = useContext(SelectedQuickResultsIdsContext);

    
    const handleInputChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        setUrl(e.currentTarget.value);
    }

    const updateCheckedResults = async (status: string, confUrl?: string) => {
        
        if(isExtended) {
            setIsLoading(true)
            let promises: Promise<any>[] = [];
            selectedQuickResultsMap.forEach((value: string[], key: string) => {
                const parts = key.split('_');
                const contentID = parts[0];
                const userID = parts[1];
                promises.push(updateMultipleRetrievedResults(userID, contentID, value, status, userToken as string, confUrl));
            });
            await Promise.all(promises);
            setSelectedQuickResultsMap(new Map());
            refreshCurrentPage!()
        }
        else {
            const newContent = await updateMultipleRetrievedResults(selectedUserId, selectedContent?._id as string, selectedResultsIds, status, userToken as string, confUrl);
            updateSelectedContent!(newContent);
            setSelectedResultsIds([]);
        }
        if(url.length > 0) {
            setUrl('');
        }
    }

    return (
        <span className="mt-6 mb-3 relative z-0 flex space-x-4 justify-center">
            <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                onClick={() => {updateCheckedResults('noInf')}}
            >   
                Set To No Infringement
            </button>
            <button
                type="button"
                className="-ml-px relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                onClick={() => {updateCheckedResults('verify')}}
            >
                Set to Verified 
            </button>
            <div className="flex">
                    <input
                        type="text"
                        name="email"
                        id="email"
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-72 rounded-none rounded-l-md  sm:text-sm border-gray-300"
                        placeholder="Enter the lumen database url here"
                        value={url}
                        onChange={handleInputChange}
                    />
                <button 
                className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                onClick={() => {updateCheckedResults('resolved', url)}}
                >
                Resolve and Add
                </button>
            </div>
        </span>
    )
}

export default AdminDashButtons;