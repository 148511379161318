import * as Yup from 'yup';

export const validationSchema = Yup.object({
  contentName: Yup.string().required('This field is required'),
  contentUrl: Yup.string().url('The provided input is not an url!').required('This field is required'),
});

export const AppSumoValidationSchema = Yup.object({
  appSumoVoucher: Yup.string().required('This field is required'),
  contentName: Yup.string().required('This field is required'),
  contentUrl: Yup.string().url('The provided input is not an url!').required('This field is required'),
});
export const AppSumoExistingUserValidationSchema = Yup.object({
  appSumoVoucher: Yup.string().required('This field is required'),
});

export const filterUrlValidation = Yup.object({
  filtered_url:  Yup.string().url('The provided input is not an url!').required('This field is required')
})