import { Fragment, useRef, useState, FC, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/outline";
import { Formik, Form } from "formik";
import { DialogOpenContext } from "../../contexts/DialogOpenContext";
import { addUserContentToUser } from "../../services/userContentService";
import { UserIdContext } from "../../contexts/UserIdContext";
import { IContentInput } from "../../shared/interfaces";
import { validationSchema } from "./../../validation/ValidationSchema";
import Tooltip from "../Tooltip/tooltip";

// TODO: fix dialog element pls

interface IProps {
  mutateContents: (title: string, url: string) => void;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const AddContentModal: FC<IProps> = ({ mutateContents }) => {
  const { open, setOpen } = useContext(DialogOpenContext);
  const [isOpen, setIsOpen] = useState(true);
  const { id, userToken } = useContext(UserIdContext);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-brandgreen">
                  <PlusIcon
                    className="h-6 w-6 text-brandblue"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Add new content
                  </Dialog.Title>
                </div>

                <Formik
                  initialValues={{
                    contentName: "",
                    contentUrl: "",
                  }}
                  enableReinitialize
                  validationSchema={validationSchema}
                  onSubmit={async (data, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    // console.log(data);

                    let newUserContent: IContentInput = {
                      title: data.contentName,
                      url: data.contentUrl,
                    };

                    const res = await addUserContentToUser(
                      id as string,
                      userToken as string,
                      newUserContent
                    );
                    if (res === "Cannot add more content") {
                      setErrorMessage(res);
                      setError(true);
                    } else if (res === "Title already exists!") {
                      setErrorMessage(res);
                      setError(true);
                    } else {
                      setSubmitting(false);
                      resetForm();
                      setOpen(false);
                      mutateContents(data.contentName, data.contentUrl);
                    }
                  }}
                >
                  {({
                    values,
                    isSubmitting,
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div>
                        <div className="mt-6 mb-3 max-w-md">
                          <label
                            htmlFor="contentName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Full Title of Content *
                            <Tooltip tooltipdesc="Please add your 'Content name + any additional values here. For example: if you sell a Theme on Envato, you can include the theme ID here. It helps Harvel to find infringements." />
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              name="contentName"
                              id="contentName"
                              className={classNames(
                                errors.contentName
                                  ? "border-red-500"
                                  : "border-gray-300",
                                "shadow-sm h-10 mb-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md "
                              )}
                              placeholder="Copy &amp; paste your content name"
                              onChange={handleChange}
                              value={values.contentName}
                            />
                          </div>
                          {errors.contentName && touched.contentName ? (
                            <p
                              className="mt-3 text-sm text-red-600 mb-2"
                              id="email-error"
                            >
                              {errors.contentName}
                            </p>
                          ) : (
                            <p></p>
                          )}
                          <div className="mb-3">
                            <label
                              htmlFor="contentUrl"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Content URL*
                              <Tooltip tooltipdesc="The main public URL where you sell your digital content." />
                              
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              <input
                                type="text"
                                name="contentUrl"
                                id="contentUrl"
                                className={classNames(
                                  errors.contentUrl
                                    ? "border-red-500"
                                    : "border-gray-300",
                                  "focus:ring-indigo-500 h-10 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                )}
                                placeholder="https://yourcontenturl.com/"
                                onChange={handleChange}
                                value={values.contentUrl}
                              />
                            </div>

                            {errors.contentUrl && touched.contentUrl ? (
                              <p
                                className="mt-3 text-sm text-red-600 mb-2"
                                id="email-error"
                              >
                                {errors.contentUrl}
                              </p>
                            ) : (
                              <p></p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brandgreen text-base font-medium text-brandblue hover:bg-brandblue hover:text-white transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        >
                          Add
                        </button>
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                          onClick={() => {
                            setOpen(false);
                            setError(false);
                          }}
                          ref={cancelButtonRef}
                        >
                          Cancel
                        </button>
                        <p
                          className={classNames(
                            error ? "block" : "hidden",
                            "mt-3 text-sm text-red-600 mb-2"
                          )}
                        >
                          {errorMessage}
                        </p>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddContentModal;
