import { FC, useContext } from 'react';
import { UserIdContext } from '../../contexts/UserIdContext';
import GetStartedForm from '../getStarted/GetStartedForm';
import HomeScreen from './HomeScreen';
import { useUser } from '../../swrHooks/useUser';


const Home: FC<{}> = () => {
  const { id, userToken } = useContext(UserIdContext);
  const { currentUser, checkIfUserHasPlan } = useUser(
    id as string,
    userToken as string
  );

  return (
    <div className='max-w-7xl mx-auto mt-6'>
      { checkIfUserHasPlan(currentUser) ? (
        <HomeScreen />
      ) : (
        <GetStartedForm />
      )}
    </div>
  );
};

export default Home;
