import { Dispatch, FC, Fragment, SetStateAction, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FlagIcon } from '@heroicons/react/solid';



interface IProps {
    viewUrls: boolean,
    setViewUrls: Dispatch<SetStateAction<boolean>>,
    confirmedUrls: string[]
}

const ShowConfirmedUrlsModal: FC<IProps> = ({viewUrls, setViewUrls, confirmedUrls}) => {
    const [open, setOpen] = useState(true);
    const cancelButtonRef = useRef(null)
    
    return (
        <Transition.Root show={viewUrls} as={Fragment}>
          <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={setOpen} initialFocus={cancelButtonRef}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
    
              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                      <FlagIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Actions Taken for Content
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Official documentation and public records for action taken on your behalf: 
                        </p>
                      </div>
                       <div className='flex flex-col mt-2'>
                       {confirmedUrls.map(url => 
                       <a href={url}  target='_blank' rel='noreferrer noopener' className='hover:underline'>
                           {url}
                      </a>)}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      onClick={() => setViewUrls(false)}
                      ref={cancelButtonRef}
                    >
                      Go back to content
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      )
}

export default ShowConfirmedUrlsModal;