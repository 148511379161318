import { FC, useState, Fragment } from 'react'
import { ExternalLinkIcon } from '@heroicons/react/solid';
import { IQuickDashRetrievedResults, IRetrievedResults } from './../../shared/interfaces';
import { Listbox, Transition, Switch } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { useEffect, useContext } from 'react';
import AdminDashConfUrlsModal from './AdminDashConfUrlsModal';
import { saveUpdatedResult } from '../../services/adminService';
import { SelectedUserIdContext } from '../../contexts/SelectedUserIdContext';
import { UserIdContext } from '../../contexts/UserIdContext';
import { ResultNotificationContext } from '../../contexts/ResultNotificationContext';
import { SelectedResultsIdsContext } from '../../contexts/SelectedResultsIdsContext';
import { SelectedQuickResultsIdsContext } from '../../contexts/SelectedQuickResultsIdsContext';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }

const typesOfInfStatuses = [
    'Not yet handled',
    'Pending',
    'Resolved by Google',
    'Resolved by Site',
    'Resolved by Google and Site',
    'No Infringement'
]; 

const AdminDashTableResult: FC<{
    result: IRetrievedResults,
    ind: number,
    userContentId: string,
    isExtended?: boolean }> = ({result, ind, userContentId, isExtended }) => {

    const [verified, setVerified] = useState<boolean | undefined>();
    const [infStatus, setInfStatus] = useState<string | undefined>();
    const [confUrls, setConfUrls] = useState<string[] | undefined>();
    const [openConfUrlModal, setOpenConfUrlModal] = useState(false);
    const [checked, setChecked] = useState(false);
    const { selectedUserId } = useContext(SelectedUserIdContext);    
    const { userToken } = useContext(UserIdContext);
    const {setNotMessage, setSuccess, setShowNotification } = useContext(ResultNotificationContext);
    const { selectedResultsIds, setSelectedResultsIds } = useContext(SelectedResultsIdsContext);
    const { selectedQuickResultsMap, setSelectedQuickResultsMap } = useContext(SelectedQuickResultsIdsContext);

    useEffect(() => {
        console.log('FIRE');
        
        // * ovie 3 se cuvaat vo state radi tailwind componentite i mora da se triggerne rerender koga se menja content
        setVerified(result.verified);
        setInfStatus(result.infringementStatus);
        setConfUrls(result.confirmationUrls);
        if(isExtended) {
            const key = (result as IQuickDashRetrievedResults).content._id + '_' + (result as IQuickDashRetrievedResults).user._id;
            setChecked(selectedQuickResultsMap.has(key) && selectedQuickResultsMap.get(key)!.includes(result._id!));
        }
        else {
            setChecked(selectedResultsIds.includes(result._id!));
        }
    },[result, selectedResultsIds, selectedQuickResultsMap, isExtended]);

    const handleCheckboxChange = (e: React.FormEvent<HTMLInputElement>) => {
        setChecked(e.currentTarget.checked);

        if(isExtended) {
            const key = (result as IQuickDashRetrievedResults).content._id + '_' + (result as IQuickDashRetrievedResults).user._id;
            
            if(e.currentTarget.checked) {
                
                const oldValue = selectedQuickResultsMap.get(key)
                const newValue = oldValue !== undefined ? [...(oldValue), result._id as string] : [result._id as string];
                const newMap: Map<string, string[]> = new Map(selectedQuickResultsMap);
                newMap.set(key, newValue);
                console.log(newMap);
                
                setSelectedQuickResultsMap(newMap);
            }
    
            if(!e.currentTarget.checked) {
                const oldValue = selectedQuickResultsMap.get(key)
                if (!oldValue) return
                if(oldValue.length < 2) {
                    const newMap: Map<string, string[]> = new Map(selectedQuickResultsMap);
                    newMap.delete(key);
                    setSelectedQuickResultsMap(newMap);
                }
                else {
                    const newValue = oldValue.filter(id => id !== result._id as string)
                    const newMap: Map<string, string[]> = new Map(selectedQuickResultsMap);
                    newMap.set(key, newValue);
                    setSelectedQuickResultsMap(newMap);
                }
            }
        }
        else {
            if(e.currentTarget.checked) {
                setSelectedResultsIds([...selectedResultsIds, result._id as string]);
            }
    
            if(!e.currentTarget.checked) {
                setSelectedResultsIds(selectedResultsIds.filter(id => id !== result._id));
            }
        }

    }

    const updateRetrievedResult = async () => {
        const newUrls = confUrls?.filter(url => !result.confirmationUrls?.includes(url));
        const userID = isExtended ? (result as IQuickDashRetrievedResults).user._id : selectedUserId;
        const status = await saveUpdatedResult(userID, userContentId, result._id as string, verified as boolean, infStatus as string, newUrls as string[], userToken as string);
        if(status === 200) {
            setNotMessage('The retrieved result has been successfully updated!');
            setSuccess(true);
            setShowNotification(true);
        } else {
            setNotMessage('Something went wrong, please try again!');
            setSuccess(false);
            setShowNotification(true);
        }
    }

    return (
        <>
        
        <tr key={result._id} className={ind % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {ind + 1}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-indigo-600">
                        <label className="inline-flex items-center mt-3">
                            <input
                                type="checkbox"
                                className="form-checkbox rounded ml-2 h-4 w-4 border-gray-400"
                                checked={checked}
                                onChange={handleCheckboxChange}
                            />
                        </label>
                    </td>
                    {isExtended &&<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {(result as IQuickDashRetrievedResults).user.displayName}
                    </td>}
                    {isExtended && <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {(result as IQuickDashRetrievedResults).content.title}
                    </td>}
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <a href={result.url} 
                      target='_blank'
                      rel='noreferrer noopener'
                      >
                        <ExternalLinkIcon className='inline-block ml-1 h-4 w-4 text-gray-400 hover:text-indigo-900'></ExternalLinkIcon>
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <Switch
                        checked={verified as boolean}
                        onChange={setVerified}
                        className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                        <span className="sr-only">Use setting</span>
                        <span aria-hidden="true" className="pointer-events-none absolute bg-white w-full h-full rounded-md" />
                        <span
                            aria-hidden="true"
                            className={classNames(
                            verified ? 'bg-indigo-600' : 'bg-gray-200',
                            'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200'
                            )}
                        />
                        <span
                            aria-hidden="true"
                            className={classNames(
                            verified ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200'
                            )}
                        />
                    </Switch>
                    </td>
                    <td className={classNames(result.isManual ? 'text-green-600' : 'text-red-600', 'px-6 py-4 whitespace-nowrap text-sm')}>{result.isManual ? 'True' : 'False'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        
                    <Listbox value={infStatus} onChange={setInfStatus}>
                        <div className="relative mt-1">
                        <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                            <span className="block truncate">{infStatus}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                                className="w-5 h-5 text-gray-400"
                                aria-hidden="true"
                            />
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            
                            <Listbox.Options 
                            className="absolute w-full py-1 mt-1 text-base overflow-auto bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                            {typesOfInfStatuses.map((status, ind) => (
                                <Listbox.Option
                                key={ind}
                                className={({ active }) =>
                                    `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'}
                                        cursor-default select-none relative py-2 pl-10 pr-4`
                                }
                                value={status}
                                >
                                {({ selected, active }) => (
                                    <>
                                    <span
                                        className={`${
                                        selected ? 'font-medium' : 'font-normal'
                                        } block truncate`}
                                    >
                                        {status}
                                    </span>
                                    {selected ? (
                                        <span
                                        className={`${
                                            active ? 'text-amber-600' : 'text-amber-600'
                                        }
                                                absolute inset-y-0 left-0 flex items-center pl-3`}
                                        >
                                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                        </span>
                                    ) : null}
                                    </>
                                )}
                                </Listbox.Option>
                            ))}
                            </Listbox.Options>
                        </Transition>
                        </div>
                    </Listbox>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <button
                    type="button"
                    className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs rounded text-indigo-600 hover:text-indigo-900 bg-indigo-100 hover:bg-indigo-200  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    onClick={() => {setOpenConfUrlModal(true)}}
                    >
                        View/Add
                    </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button 
                       className=" px-3 py-1.5 rounded font-medium  text-green-700 bg-green-50 hover:bg-green-200"
                       onClick={updateRetrievedResult}
                       >
                        Update
                      </button>
                    </td>
                  </tr>
                  <AdminDashConfUrlsModal confUrls={confUrls} setConfUrls={setConfUrls} openConfUrlModal={openConfUrlModal} setOpenConfUrlModal={setOpenConfUrlModal}/>
                  </>
    );
}

export default AdminDashTableResult;