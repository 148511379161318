import { FC, useState, useEffect, useContext } from "react";
import HarvelLogo from "../../shared/harvel-social-icon.jpg";
import { RadioGroup } from "@headlessui/react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { createCheckoutSession } from "../../services/stripeService";
import StripeProduct from "../stripe/StripeProduct";
// import TrialProduct from "../stripe/TrialProduct";
import { UserIdContext } from "../../contexts/UserIdContext";
import { Formik, Form } from "formik";
import { validationSchema } from "./../../validation/ValidationSchema";
import { addTrialToUser, addUserContentToUser } from "../../services/userContentService";
import {
  IContentInput,
  ISubmitGetStartedFormData,
  ISubmitGetStartedFormMethods,
} from "../../shared/interfaces";
import { useHistory } from "react-router";
import Tooltip from "../Tooltip/tooltip";
// import TrialProduct from "../stripe/TrialProduct";
// import { useHistory } from "react-router-dom";

const GetStartedForm: FC<{}> = () => {
  // const [selected, setSelected] = useState<string | undefined>();
  const [selected, setSelected] = useState<string | undefined>("Lite Package");
  const [stripe, setStripe] = useState<Stripe | null>(null);

  const [showStripePlanError, setShowStripePlanError] =
    useState<boolean>(false);

  const { id, userToken } = useContext(UserIdContext);

  useEffect(() => {
    setPublicKey();
    return () => {
      setStripe(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPublicKey = async () => {
    if (!stripe) {
      const stripeTmp = await loadStripe(
        process.env.REACT_APP_STRIPE_PUBLIC_KEY as string
      );
      setStripe(stripeTmp);
    }
  };

  // * copied function from rewardful
  // function getClientReferenceId() {
  // //eslint-disable-next-line no-mixed-operators
  //   return window.Rewardful && window.Rewardful.referral || ('checkout_'+(new Date()).getTime());
  // }

  const stripeCheckout = async (priceId: string) => {
    const { sessionId } = await createCheckoutSession(id as string, priceId);
    // console.log(sessionId);

    try {
      return stripe?.redirectToCheckout({
        sessionId: sessionId,
        // clientReferenceId: getClientReferenceId()
      });
    } catch (err) {
      // console.log(err);
    }
  };
   const history = useHistory();
  const onFormSubmit = async (
    data: ISubmitGetStartedFormData,
    { setSubmitting, resetForm }: ISubmitGetStartedFormMethods
  ) => {
    setSubmitting(true);

    // console.log(data, selected);

    let newUserContent: IContentInput = {
      title: "",
    };
    if (data.contentUrl === "") {
      newUserContent = {
        title: data.contentName,
      };
    } else {
      newUserContent = {
        title: data.contentName,
        url: data.contentUrl,
      };
    }

    if (selected === "Lite Package") {
      setShowStripePlanError(false);
      resetForm();
      addUserContentToUser(id as string, userToken as string, newUserContent);
      stripeCheckout(process.env.REACT_APP_STARTER_PRICE as string);
    } else if (selected === "Creator Package") {
      setShowStripePlanError(false);
      addUserContentToUser(id as string, userToken as string, newUserContent);
      resetForm();
      stripeCheckout(process.env.REACT_APP_CREATOR_PRICE as string);
    } else if (selected === "Pro Package") {
      setShowStripePlanError(false);
      addUserContentToUser(id as string, userToken as string, newUserContent);
      resetForm();
      stripeCheckout(process.env.REACT_APP_PREMIUM_PRICE as string);
    } else if (selected === "Free") {
      setShowStripePlanError(false);
      await addTrialAndContentToUser(newUserContent)
    } else {
      setShowStripePlanError(true);
      setTimeout(() => {
        setShowStripePlanError(false);
      }, 4000);
    }

    setSubmitting(false);
  };

  const addTrialAndContentToUser = async (newUserContent: IContentInput) => {
      const status = await addTrialToUser(id as string, userToken as string);
      if(status === 200){
        const contentStatus =  await addUserContentToUser(id as string, userToken as string, newUserContent);
        if(contentStatus === 200){
            setTimeout(() => {
              history.push("/content");
            }, 800);
        }
      }
  }

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div className="min-h-screen flex flex-col justify-center py-10 sm:px-6 lg:px-8">
        <div className="mt-0 sm:mx-auto sm:w-full sm:max-w-lg bg-white w-full space-y-8 shadow-lg rounded-xl py-10 p-4 lg:p-14">
          <div className="sm:mx-auto sm:w-full sm:max-w-md ">
            <img
              className="mx-auto h-12 w-auto"
              src={HarvelLogo}
              alt="Harvel"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-brandblue">
              Add your first content and subscribe
            </h2>
          </div>

          <Formik
            initialValues={{
              contentName: "",
              contentUrl: "",
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onFormSubmit}
          >
            {({
              values,
              isSubmitting,
              handleChange,
              handleSubmit,
              errors,
              touched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div>
                  <div>
                    <label
                      htmlFor="contentName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      * Content Name
                      <Tooltip tooltipdesc="Please add your 'Content name + any additional values here. For example: if you sell a Theme on Envato, you can include the theme ID here. It helps Harvel to find infringements." />
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        type="text"
                        name="contentName"
                        id="contentName"
                        className={classNames(
                          errors.contentName
                            ? "border-red-500"
                            : "border-gray-300",
                          "shadow-sm h-10 mb-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md "
                        )}
                        placeholder="Enter your content name"
                        onChange={handleChange}
                        value={values.contentName}
                      />
                    </div>

                    {errors.contentName && touched.contentName ? (
                      <p
                        className="mt-3 text-sm text-red-600 mb-2"
                        id="email-error"
                      >
                        {errors.contentName}
                      </p>
                    ) : (
                      <p></p>
                    )}
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="contentUrl"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Content URL
                      <Tooltip tooltipdesc="The main public URL where you sell your digital content." />
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        type="text"
                        name="contentUrl"
                        id="contentUrl"
                        className={classNames(
                          errors.contentUrl
                            ? "border-red-500"
                            : "border-gray-300",
                          "focus:ring-indigo-500 h-10 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        )}
                        placeholder="https://yourcontenturl.com/"
                        onChange={handleChange}
                        value={values.contentUrl}
                      />
                    </div>

                    {errors.contentUrl && touched.contentUrl ? (
                      <p
                        className="mt-3 text-sm text-red-600 mb-2"
                        id="email-error"
                      >
                        {errors.contentUrl}
                      </p>
                    ) : (
                      <p></p>
                    )}
                  </div>

                  <div className="flex items-center justify-between mb-3">
                    <div className="w-full max-w-md mx-auto">
                      <RadioGroup value={selected} onChange={setSelected}>
                        <RadioGroup.Label className="sr-only">
                          Server size
                        </RadioGroup.Label>
                        <div className="space-y-2">
                           {/* <TrialProduct
                              title="Free"
                              price="$0.00 USD"
                              description="1 Content - No Credit card & 2 take-downs per month"
                            /> */}
                          <StripeProduct
                            title="Lite Package"
                            price="$49.00 USD"
                            description="Up to 1 content"
                          />
                          <StripeProduct
                            title="Creator Package"
                            price="$99.00 USD"
                            description="Up to 10 contents"
                          />
                          <StripeProduct
                            title="Pro Package"
                            price="$199.00 USD"
                            description="Up to 30 contents"
                          />
                        </div>
                      </RadioGroup>
                    </div>
                  </div>
                  {showStripePlanError ? (
                    <p
                      className="mt-3 text-sm text-red-600 mb-2"
                      id="email-error"
                    >
                      Please select one package!
                    </p>
                  ) : (
                    <p></p>
                  )}

                  <div>
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brandcolor hover:bg-brandblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300"
                    >
                      Subscribe
                    </button>
                  </div>
                  <p className="text-center mt-1 font-normal text-sm">
                  Free 7 Day Trial On All Packages
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default GetStartedForm;
