import { useState, useContext } from 'react';

// import { Dialog, Transition } from '@headlessui/react';
// import { CheckIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import { useUser } from "../../swrHooks/useUser";
import appsumoService from '../../services/appsumoService';
import { IContentInput, ISubmitRedeemAppSumoFormData, ISubmitRedeemAppSumoExistingUserFormData, ISubmitRedeemAppSumoFormMethods, ISubmitRedeemAppSumoExistingUserFormMethods } from '../../shared/interfaces';
import { UserIdContext } from '../../contexts/UserIdContext';
import { Form, Formik } from 'formik';
import Tooltip from '../Tooltip/tooltip';

import HarvelLogo from "../../shared/harvel-social-icon.jpg";

import { AppSumoValidationSchema, AppSumoExistingUserValidationSchema } from "./../../validation/ValidationSchema";
import { addUserContentToUser } from '../../services/userContentService';


export default function AppSumo() {
    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(" ");
    }
    const { id, userToken } = useContext(UserIdContext);
    const currentUser = useUser(
        id as string,
        userToken as string
    ).currentUser;

    const [result_message, setresult_message] = useState('');
    const history = useHistory();

    // //! todo: also check if stripecustomerid contains appsumo
    // console.warn(UserIdContext);
    // console.warn(id);
    console.warn(currentUser);
    

    if (currentUser && currentUser.stripeCustomerId && currentUser.stripeCustomerId.includes("appsumo")) {
        console.log('found existing user');
        const onFormSubmitExisting = async (
            data: ISubmitRedeemAppSumoExistingUserFormData,
            { setSubmitting, resetForm }: ISubmitRedeemAppSumoExistingUserFormMethods
        ) => {
            console.log('submit form');
            setSubmitting(true);
            setresult_message('');

            appsumoService.redeem(currentUser, data.appSumoVoucher, userToken).then((resp) => {
                console.log(resp);
                if (resp.status === 200) {
                    history.push("/home");
                } else {
                    console.warn('error');
                    console.warn(resp);
                    setresult_message(resp.data);
                }
            }).catch((err) => {

                console.warn('error');
                console.warn(err);
                setresult_message(err.response.data);
            });

            // console.log(data, selected);


            setSubmitting(false);
        };

        return (
            <>
                <div className="min-h-screen flex flex-col justify-center py-10 sm:px-6 lg:px-8">
                    <div className="mt-0 sm:mx-auto sm:w-full sm:max-w-lg bg-white w-full space-y-8 shadow-lg rounded-xl py-10 p-4 lg:p-14">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md ">
                            <img
                                className="mx-auto h-12 w-auto"
                                src={HarvelLogo}
                                alt="Harvel"
                            />
                            <h2 className="mt-6 text-center text-3xl font-extrabold text-brandblue">
                                Redeem AppSumo Voucher
                            </h2>
                            <p>Add another AppSumo Voucher to upgrade your plan</p>
                        </div>

                        <Formik
                            initialValues={{
                                appSumoVoucher: "",
                            }}
                            enableReinitialize
                            validationSchema={AppSumoExistingUserValidationSchema}
                            onSubmit={onFormSubmitExisting}
                        >
                            {({
                                values,
                                isSubmitting,
                                handleChange,
                                handleSubmit,
                                errors,
                                touched,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div>

                                        <div className="mb-3">
                                            <label
                                                htmlFor="contentUrl"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                * AppSumo Voucher
                                                <Tooltip tooltipdesc="Enter the AppSumo Voucher you wish to redeem" />
                                            </label>
                                            <div className="mt-1 relative rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    name="appSumoVoucher"
                                                    id="appSumoVoucher"
                                                    className={classNames(
                                                        errors.appSumoVoucher
                                                            ? "border-red-500"
                                                            : "border-gray-300",
                                                        "focus:ring-indigo-500 h-10 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    )}
                                                    placeholder="ABCD1234"
                                                    onChange={handleChange}
                                                    value={values.appSumoVoucher}
                                                />
                                            </div>

                                            {errors.appSumoVoucher && touched.appSumoVoucher ? (
                                                <p
                                                    className="mt-3 text-sm text-red-600 mb-2"
                                                    id="email-error"
                                                >
                                                    {errors.appSumoVoucher}
                                                </p>
                                            ) : (
                                                <p
                                                    className="mt-3 text-sm text-red-600 mb-2"
                                                    id="email-error"
                                                >
                                                    {result_message}</p>
                                            )}
                                        </div>

                                        <div>
                                            <button disabled={isSubmitting} type="submit"
                                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brandcolor hover:bg-brandblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300"
                                            >Redeem voucher</button>
                                        </div>
                                        <p className="text-center mt-1 font-normal text-sm">

                                        </p>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </>
        );
    } else {

        const onFormSubmit = async (
            data: ISubmitRedeemAppSumoFormData,
            { setSubmitting, resetForm }: ISubmitRedeemAppSumoFormMethods
        ) => {
            console.log('send first appsumo form');
            setSubmitting(true);
            setresult_message('');

            let newUserContent: IContentInput = {
                title: "",
            };
            if (data.contentUrl === "") {
                newUserContent = {
                    title: data.contentName,
                };
            } else {
                newUserContent = {
                    title: data.contentName,
                    url: data.contentUrl,
                };
            }

            appsumoService.redeem(currentUser, data.appSumoVoucher, userToken).then((resp) => {
                console.log(resp);
                if (resp.status === 200) {
                    addUserContentToUser(id as string, userToken as string, newUserContent);
                    history.push("/home");
                } else {
                    console.warn('error');
                    console.warn(resp);
                    setresult_message(resp.data);
                }
            }).catch((err) => {

                console.warn('error');
                console.warn(err);
                setresult_message(err.response.data);
            });

            setSubmitting(false);
        };

        return (
            <>
                <div className="min-h-screen flex flex-col justify-center py-10 sm:px-6 lg:px-8">
                    <div className="mt-0 sm:mx-auto sm:w-full sm:max-w-lg bg-white w-full space-y-8 shadow-lg rounded-xl py-10 p-4 lg:p-14">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md ">
                            <img
                                className="mx-auto h-12 w-auto"
                                src={HarvelLogo}
                                alt="Harvel"
                            />
                            <h2 className="mt-6 text-center text-3xl font-extrabold text-brandblue">
                                Redeem your AppSumo Voucher and add your first content
                            </h2>
                        </div>

                        <Formik
                            initialValues={{
                                appSumoVoucher: "",
                                contentName: "",
                                contentUrl: "",
                            }}
                            enableReinitialize
                            validationSchema={AppSumoValidationSchema}
                            onSubmit={onFormSubmit}
                        >
                            {({
                                values,
                                isSubmitting,
                                handleChange,
                                handleSubmit,
                                errors,
                                touched,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div>

                                        <div className="mb-3">
                                            <label
                                                htmlFor="contentUrl"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                * AppSumo Voucher
                                                <Tooltip tooltipdesc="Enter the AppSumo Voucher you wish to redeem" />
                                            </label>
                                            <div className="mt-1 relative rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    name="appSumoVoucher"
                                                    id="appSumoVoucher"
                                                    className={classNames(
                                                        errors.appSumoVoucher
                                                            ? "border-red-500"
                                                            : "border-gray-300",
                                                        "focus:ring-indigo-500 h-10 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    )}
                                                    placeholder="ABCD1234"
                                                    onChange={handleChange}
                                                    value={values.appSumoVoucher}
                                                />
                                            </div>

                                            {errors.appSumoVoucher && touched.appSumoVoucher ? (
                                                <p
                                                    className="mt-3 text-sm text-red-600 mb-2"
                                                    id="email-error"
                                                >
                                                    {errors.appSumoVoucher}
                                                </p>
                                            ) : (
                                                <p
                                                    className="mt-3 text-sm text-red-600 mb-2"
                                                    id="email-error"
                                                >
                                                    {result_message}</p>
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <label
                                                htmlFor="contentName"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                * Content Name
                                                <Tooltip tooltipdesc="Please add your 'Content name + any additional values here. For example: if you sell a Theme on Envato, you can include the theme ID here. It helps Harvel to find infringements." />
                                            </label>
                                            <div className="mt-1 relative rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    name="contentName"
                                                    id="contentName"
                                                    className={classNames(
                                                        errors.contentName
                                                            ? "border-red-500"
                                                            : "border-gray-300",
                                                        "shadow-sm h-10 mb-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md "
                                                    )}
                                                    placeholder="Enter your content name"
                                                    onChange={handleChange}
                                                    value={values.contentName}
                                                />
                                            </div>

                                            {errors.contentName && touched.contentName ? (
                                                <p
                                                    className="mt-3 text-sm text-red-600 mb-2"
                                                    id="email-error"
                                                >
                                                    {errors.contentName}
                                                </p>
                                            ) : (
                                                <p></p>
                                            )}
                                        </div>

                                        <div className="mb-8">
                                            <label
                                                htmlFor="contentUrl"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                * Content URL
                                                <Tooltip tooltipdesc="The main public URL where you sell your digital content." />
                                            </label>
                                            <div className="mt-1 relative rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    name="contentUrl"
                                                    id="contentUrl"
                                                    className={classNames(
                                                        errors.contentUrl
                                                            ? "border-red-500"
                                                            : "border-gray-300",
                                                        "focus:ring-indigo-500 h-10 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    )}
                                                    placeholder="https://yourcontenturl.com/"
                                                    onChange={handleChange}
                                                    value={values.contentUrl}
                                                />
                                            </div>

                                            {errors.contentUrl && touched.contentUrl ? (
                                                <p
                                                    className="mt-3 text-sm text-red-600 mb-2"
                                                    id="email-error"
                                                >
                                                    {errors.contentUrl}
                                                </p>
                                            ) : (
                                                <p></p>
                                            )}
                                        </div>


                                        <div>
                                            <button disabled={isSubmitting} type="submit"
                                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brandcolor hover:bg-brandblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300"
                                            >Redeem voucher</button>
                                        </div>
                                        <p className="text-center mt-1 font-normal text-sm">

                                        </p>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </>
        );
    }
}
