import axios, { isAxiosError } from "../axios/axios-config";
import { IContentInput, IRetrievedResults } from "../shared/interfaces";

export const addRetrievedUrlToUserContent = async (
  userId: string,
  token: string,
  userContentId: string,
  retrievedResult: IRetrievedResults
) => {
  const res = await axios.post(
    `/user/addRetrievedResultToUserContent/${userId}`,
    { userContentId, retrievedResult },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res.status;
};

export const addUserContentToUser = async (
  userId: string,
  token: string,
  userContent: IContentInput
) => {
  try {
    const res = await axios.post(
      `/user/addUserContentToUser/${userId}`,
      { userContent: userContent },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return res.status;
  } catch (err) {
    if (isAxiosError(err)) {
      return err.response?.data;
    }
  }

};

export const addTrialToUser = async (
  userId: string,
  token: string
) => {
  try {
    const res = await axios.patch(
      `/user/addTrialToUser/${userId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    console.log(res.status);
    return res.status;
  } catch (err) {
    if (isAxiosError(err)) {
      return err.response?.data;
    }
  }
};

export const changeUserContentStatus = async (
  userId: string,
  token: string,
  userContentId: string,
  newStatus: boolean
) => {
  const res = await axios.patch(
    `/user/changeUserContentStatus/${userId}`,
    { userContentId, newStatus },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return res.status;
};

export const addContentVerificationRequest = async (
  userId: string,
  token: string,
  userContentId: string,
) => {
  const res = await axios.patch(
    `/user/addContentVerificationRequest/${userId}`,
    { userContentId },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return res.status;
};


