import { FC, useContext, useState } from "react";
import { AdminQuickSearchContext } from "../../contexts/AdminQuickSearchContext";

const AdminDashTableHead: FC<{
  isExtended?: boolean, 
  selectAllOnCurrentPage: (selection: boolean) => void,
  }> = ({isExtended, selectAllOnCurrentPage}) => {
  
    const { searchKeysMap, updateSearchKeysMap } = useContext(AdminQuickSearchContext);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        updateSearchKeysMap(name, value);
    }
    
    const [checked, setChecked] = useState(false);
    const handleCheckboxChange = (e: React.FormEvent<HTMLInputElement>) => {
      const isChecked = e.currentTarget.checked;
      setChecked(isChecked);
      selectAllOnCurrentPage(isChecked);
    }
    
    return(
    <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    No
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Select
                    {
                      !isExtended && <input
                        type="checkbox"
                        className="form-checkbox rounded ml-1 h-4 w-4 border-gray-400"
                        checked={checked}
                        onChange={handleCheckboxChange}
                      />
                    }
                  </th>
                  {isExtended && <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    User
                  </th>}
                  {isExtended && <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Content
                  </th>}
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Url
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                   Manual Verified
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Manual
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    infringementStatus
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    confirmationUrls
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Update</span>
                  </th>
                </tr>
                {isExtended && <tr>
                  <th scope="col" className="relative px-6 py-3" />
                  <th scope="col" className="relative px-6 py-3" >
                    <input
                          type="checkbox"
                          className="form-checkbox rounded ml-1 h-4 w-4 border-gray-400"
                          checked={checked}
                          onChange={handleCheckboxChange}
                      />
                  </th>
                  <th
                      scope="col"
                      className="px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                      <label htmlFor="table-search" className="sr-only">Search</label>
                      <div className="relative">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                              <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                          </div>
                          <input
                              type="text"
                              name="userName"
                              id="table-search"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Search for items"
                              onChange={handleChange}
                              value={searchKeysMap.get('userName')} />
                      </div>
                  </th>
                  <th
                      scope="col"
                      className="px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                      <label htmlFor="table-search" className="sr-only">Search</label>
                      <div className="relative">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                              <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                          </div>
                          <input
                              type="text"
                              name="contentName"
                              id="content-search"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Search for items"
                              onChange={handleChange}
                              value={searchKeysMap.get('contetName')} />
                      </div>
                  </th>
                  <th scope="col" className="relative px-6 py-3" />
                  <th scope="col" className="relative px-6 py-3" />
                  <th scope="col" className="relative px-6 py-3" />
                  <th scope="col" className="relative px-6 py-3" />
                  <th scope="col" className="relative px-6 py-3" />
                  <th scope="col" className="relative px-6 py-3" />
                </tr>}
              </thead>
    );
}

export default AdminDashTableHead;