import { createContext } from 'react';

export type UserId = {
  id: string | null;
  setId: (id: string) => void;
  userToken: string | null;
  setUserToken: (token: string) => void;
};

export const UserIdContext = createContext<UserId>({
  id: null,
  setId: () => {},
  userToken: null,
  setUserToken: () => {},
});
