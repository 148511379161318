import { createContext } from 'react';

export type AdminQuickLoading = {
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
};

export const AdminQuickLoadingContext = createContext<AdminQuickLoading>({
  isLoading: true,
  setIsLoading: (loading: boolean) => {},
});
