import { FC } from 'react';
import { AdminDashQuickMode } from '../../../core/constants';

const AdminDashSwitch: FC<{viewMode: AdminDashQuickMode, updateViewMode: (mode: AdminDashQuickMode) => void}> = ({viewMode, updateViewMode}) => {

    return (
        <>
            <div className='flex mt-6 justify-around'>
                <button onClick={() => updateViewMode(AdminDashQuickMode.OutstandingLinks)}>
                    Outstanding URLs
                </button>
                <button onClick={() => updateViewMode(AdminDashQuickMode.NewContent)}>
                    New Content
                </button>
            </div>
        </>  
    );
};

export default AdminDashSwitch