import { FC } from 'react';
import UserMessage from './elements/UserMessage';
import StatCards from './elements/StatCards';
import { Blog } from '../blog/Blog';
import Socials from './elements/Socials';

const HomeScreen: FC<{}> = () => {
  return (
    <>
      <UserMessage />
      <StatCards />
      <Blog />
      <Socials />
    </>
  );
};

export default HomeScreen;
