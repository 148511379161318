import { FC } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";
interface IProductProps {
  title: string;
  price: string;
  description: string;
}

const StripeProduct: FC<IProductProps> = ({ title, price, description }) => {
  return (
    <RadioGroup.Option
      key={title}
      value={title}
      className={({ active, checked }) =>
        `${
          active
            ? "ring-2 ring-offset-2 ring-offset-light-blue-300 ring-white ring-opacity-60"
            : ""
        }
          ${checked ? "bg-brandlightblue bg-opacity-100" : ""}
            relative rounded-lg shadow-md px-5 py-4 cursor-pointer flex focus:outline-none`
      }
    >
      {({ active, checked }) => (
        <>
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <div className="text-sm">
                <RadioGroup.Label
                  as="p"
                  className={`font-medium  ${checked ? "" : "text-gray-900"}`}
                >
                  {title}
                </RadioGroup.Label>
                <RadioGroup.Description
                  as="span"
                  className={`inline ${
                    checked ? "text-light-blue-100" : "text-gray-500"
                  }`}
                >
                  <span>{description}</span>{" "}
                  <span aria-hidden="true">&middot;</span> <span>{price}</span>
                </RadioGroup.Description>
              </div>
            </div>
            {checked && (
              <div className="flex-shrink-0 ">
                <CheckIcon className="w-6 h-6" />
              </div>
            )}
          </div>
        </>
      )}
    </RadioGroup.Option>
  );
};

export default StripeProduct;
