import { Dispatch, FC, Fragment, SetStateAction, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FlagIcon } from '@heroicons/react/solid';
import { PlusIcon } from '@heroicons/react/solid'

const AdminDashConfUrlsModal:FC<{confUrls: string[] | undefined, setConfUrls:Dispatch<SetStateAction<string[] | undefined>>, openConfUrlModal: boolean, setOpenConfUrlModal: React.Dispatch<React.SetStateAction<boolean>> }> =
     ({confUrls, setConfUrls, openConfUrlModal, setOpenConfUrlModal}) => {

    const [open, setOpen] = useState(true);
    const cancelButtonRef = useRef(null);
    const [newUrl, setNewUrl] = useState('');

    const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        setNewUrl(event.currentTarget.value);
    }

    const addConfUrl = () => {
        setConfUrls([...confUrls as string[], newUrl]);
        setNewUrl('');
    }
    
    return (
        <Transition.Root show={openConfUrlModal} as={Fragment}>
          <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={setOpen} initialFocus={cancelButtonRef}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
    
              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 ">
                      <FlagIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Confirmation Urls for this result
                      </Dialog.Title>
                       <div className='flex flex-col mt-2'>
                         { confUrls && confUrls.length > 0 ?
                       confUrls?.map((url, ind) => 
                       <a href={url} key={ind}  target='_blank' rel='noreferrer noopener' className='hover:underline'>
                           {url}
                      </a>) : <div>No urls yet!</div>}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="mr-3 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      onClick={() => {setOpenConfUrlModal(false)}}
                      ref={cancelButtonRef}
                    >
                      Back
                    </button>
                  </div>
                  <div>
                  <label htmlFor="url" className="block text-sm font-medium text-gray-700 mt-3">
                    Add new confirmed Url
                  </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                        <div className="relative flex items-stretch flex-grow focus-within:z-10">
                        <input
                            type="text"
                            name="url"
                            id="url"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none sm:text-sm border-gray-300"
                            value={newUrl}
                            onChange={handleInputChange}
                        />
                        </div>
                        <button 
                        className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                        type='button'
                        onClick={addConfUrl}
                        >
                        <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span>Add</span>
                        </button>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      )
}

export default AdminDashConfUrlsModal;