import { createContext } from 'react';

export type SelectedQuickResultsIds = {
    selectedQuickResultsMap: Map<string, string[]>,
    setSelectedQuickResultsMap: (data: Map<string, string[]>) => void
}

export const SelectedQuickResultsIdsContext = createContext<SelectedQuickResultsIds>({
    selectedQuickResultsMap: new Map() as Map<string, string[]>,
    setSelectedQuickResultsMap: () => {},
}) 