import { FC, useContext } from 'react';
import { AdminQuickLoadingContext } from '../../../../contexts/AdminQuickLoadingContext';
import { QuickModePageSize } from '../../../../core/constants';
import { IQuickDashRetrievedResults } from '../../../../shared/interfaces';
import AdminDashTableHead from '../../AdminDashTableHead';
import AdminDashTableResult from '../../AdminDashTableResult';
import Loading from '../../../loading/loading';

const AdminQuickOutstandingTable: FC<{
  entries: IQuickDashRetrievedResults[],
  page: number,
  selectAllOnCurrentPage: (selection: boolean) => void,
}> = ({entries, page, selectAllOnCurrentPage}) => {

  const { isLoading } = useContext(AdminQuickLoadingContext)

  return (
    <div> 
      <div className="flex flex-col mt-6">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow border-b overflow-visible border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <AdminDashTableHead isExtended={true} selectAllOnCurrentPage={selectAllOnCurrentPage} />
                {!isLoading && <tbody>
                  {entries.map((result, Idx) => (
                    <AdminDashTableResult result={result} ind={(page - 1) * QuickModePageSize + Idx} userContentId={result.content._id} key={Idx} isExtended={true} />
                  ))}
                </tbody>}
              </table>
              {
                isLoading && <Loading minimised={true} />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminQuickOutstandingTable