import { createContext } from 'react';

export type VerifyContent = {
  openVerifyContentModal: boolean;
  setOpenVerifyContentModal: (open: boolean) => void;
};

export const VerifyContentContext = createContext<VerifyContent>({
  openVerifyContentModal: false,
  setOpenVerifyContentModal: () => {},
});
