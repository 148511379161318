import { FC } from 'react'
import { useContext } from 'react';
import { UserIdContext } from '../../contexts/UserIdContext';
import { useUserStats } from './../../swrHooks/useUserStats';



const AdminDashUserStats: FC<{userId: string}> = ({ userId }) => {

    const { userToken } = useContext(UserIdContext);
    const { userStats } = useUserStats(userId, userToken as string);

    const stats = [
        {
            name: 'Total Urls Tracked',
            stat: userStats ? userStats.totalUrlsTracked : 'Calculating' 
        }, 
        {
            name: 'Pending DMCA Takedowns',
            stat: userStats ? userStats.totalTakedownsPending : 'Calculating'
        },
        {
            name: 'Successful DMCA Takedowns',
            stat: userStats ? userStats.totalTakedownsCompleted : 'Calculating'
        }
    ]

    return (
        <div>
            <h3 className="mt-6 text-lg leading-6 font-medium text-gray-900">Stats for our Customers</h3>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            {stats.map((item) => (
                <div key={item.name} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">{item.stat}</dd>
                </div>
            ))}
            </dl>
        </div>
    );
}

export default AdminDashUserStats;