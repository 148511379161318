import { FC, useContext, useEffect, useState } from "react";
import HarvelLogo from "../../shared/harvel-social-icon.jpg";
import { RadioGroup } from "@headlessui/react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { createCheckoutSession } from "../../services/stripeService";
import StripeProduct from "../stripe/StripeProduct";
import { UserIdContext } from "../../contexts/UserIdContext";

const GetStartedForm: FC<{}> = () => {
  const [selected, setSelected] = useState<string | undefined>();
  const [stripe, setStripe] = useState<Stripe | null>(null);

  const [showStripePlanError, setShowStripePlanError] =
    useState<boolean>(false);

  const { id } = useContext(UserIdContext);

  useEffect(() => {
    setPublicKey();
    return () => {
      setStripe(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPublicKey = async () => {
    if (!stripe) {
      const stripeTmp = await loadStripe(
        process.env.REACT_APP_STRIPE_PUBLIC_KEY as string
      );
      setStripe(stripeTmp);
    }
  };

  const stripeCheckout = async (priceId: string) => {
    const { sessionId } = await createCheckoutSession(id as string, priceId);
    try {
      return stripe?.redirectToCheckout({
        sessionId
      });
    } catch (err) {
      console.log(err);
    }
  };

  const changeSubscription = async () => {

    if (selected === "Lite Package") {
      setShowStripePlanError(false);
      await stripeCheckout(process.env.REACT_APP_STARTER_PRICE as string);
    } else if (selected === "Creator Package") {
      setShowStripePlanError(false);
      await stripeCheckout(process.env.REACT_APP_CREATOR_PRICE as string);
    } else if (selected === "Pro Package") {
      setShowStripePlanError(false);
      await stripeCheckout(process.env.REACT_APP_PREMIUM_PRICE as string);
    } else {
      setShowStripePlanError(true);
      setTimeout(() => {
        setShowStripePlanError(false);
      }, 4000);
    }
  };

  return (
    <>
      <div className="min-h-screen flex flex-col justify-center py-10 sm:px-6 lg:px-8">
        <div
          className="mt-0 sm:mx-auto sm:w-full sm:max-w-lg bg-white w-full space-y-8 shadow-lg rounded-xl py-10 p-4 lg:p-14">
          <div className="sm:mx-auto sm:w-full sm:max-w-md ">
            <img
              className="mx-auto h-12 w-auto"
              src={HarvelLogo}
              alt="Harvel"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-brandblue">
              Choose your subscription plan
            </h2>
          </div>

          <div>
            <div className="flex items-center justify-between mb-3">
              <div className="w-full max-w-md mx-auto">
                <RadioGroup value={selected} onChange={setSelected}>
                  <RadioGroup.Label className="sr-only">
                    Server size
                  </RadioGroup.Label>
                  <div className="space-y-2">
                    <StripeProduct
                      title="Lite Package"
                      price="$49.00 USD"
                      description="Up to 1 content"
                    />
                    <StripeProduct
                      title="Creator Package"
                      price="$99.00 USD"
                      description="Up to 10 contents"
                    />
                    <StripeProduct
                      title="Pro Package"
                      price="$199.00 USD"
                      description="Up to 30 contents"
                    />
                  </div>
                </RadioGroup>
              </div>
            </div>
            {showStripePlanError ? (
              <p
                className="mt-3 text-sm text-red-600 mb-2"
                id="email-error"
              >
                Please select one package!
              </p>
            ) : (
              <p></p>
            )}

            <div>
              <button
                onClick={() => changeSubscription}
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brandcolor hover:bg-brandblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300"
              >
                Subscribe
              </button>
            </div>
            <p className="text-center mt-1 font-normal text-sm">
              Free 7 Day Trial On All Packages
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetStartedForm;
