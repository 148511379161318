const BlogHeader = () => {
    return(
      <div className="mb-16 w-full text-center">
      <span className="text-brandcolor font-bold">
        Read &amp; Digest
      </span>
      <h2 className="text-4xl lg:text-5xl font-bold font-heading">
        Our Blog
      </h2>
    </div>
    );
}

export default BlogHeader;