import useSWR from 'swr';
import usersService from '../services/usersService';
import { IUserStats } from '../shared/interfaces';

const fetchWithToken = (url: string, token: string) => {
  return usersService.calculateUserStats(url, token);
};

export const useUserStats = (id: string, token: string) => {
  const { data, error } = useSWR(
    id && token ? [`user/calculateStats/${id}`, token] : null,
    fetchWithToken
  );
  // console.log(data);

  return {
    userStats: data as IUserStats,
    isLoadingData: !error && !data,
    isError: error,
  };
};
