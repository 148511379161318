import { NavLink } from "react-router-dom";
import { FC } from 'react';

const NavItem:FC<{name: string, path: string, Icon: () => JSX.Element, setToggleSidebar: React.Dispatch<React.SetStateAction<boolean>> }> = ({name, path, Icon, setToggleSidebar}) => {

    return (
        <NavLink
            key={name}
            to={path}
            onClick={() => setToggleSidebar(false)}
            className="flex items-center pl-3 py-3 pr-4 hover:bg-brandthinblue text-brandblue rounded text-sm font-medium"
            activeClassName='bg-brandlightblue'
        >
            <Icon />
            <span>{name}</span>
        </NavLink>
    );
}

export default NavItem;