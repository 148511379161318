import { useEffect, useState } from 'react';
import { UserIdContext, UserId } from './contexts/UserIdContext';
import {
  UserContentInfoContext,
  UserContentInfo,
} from './contexts/UserContentInfoContext';
import Layout from './components/layout/layout';
import ReactGA from 'react-ga';
import * as FullStory from '@fullstory/browser';

function App() {
  const [id, setId] = useState<string | null>(null);
  const [userToken, setUserToken] = useState<string | null>(null);

  const [userContentTitle, setUserContentTitle] = useState<string | null>(null);
  const [userContentUrl, setUserContentUrl] = useState<string | null>(null);

  useEffect(() => {
    if(process.env.NODE_ENV !== 'development') {
    ReactGA.initialize('UA-188954920-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    FullStory.init({orgId: '1272S0', devMode: false});
    }
  }, []);


  return (
    <>
      <UserContentInfoContext.Provider
        value={
          {
            userContentTitle,
            setUserContentTitle,
            userContentUrl,
            setUserContentUrl,
          } as UserContentInfo
        }
      >
        <UserIdContext.Provider
          value={{ id, setId, userToken, setUserToken } as UserId}
        >
          <Layout />
        </UserIdContext.Provider>
      </UserContentInfoContext.Provider>
    </>
  );
}

export default App;
