import React, { FC, useContext } from 'react';
import { useUser } from '../../swrHooks/useUser';
import { UserIdContext } from '../../contexts/UserIdContext';
import StripePortal from '../stripe/StripePortal';
import axios from 'axios';
import { PencilIcon, SaveIcon } from '@heroicons/react/solid';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Settings: FC<{}> = () => {

  const { id, userToken } = useContext(UserIdContext);
  const { currentUser } = useUser(
    id as string,
    userToken as string
  );

  // const userName = useRef<HTMLInputElement>(null);


  let [doingNameChange, setShowNameChange] = React.useState(false)

  const doNameChange = () => {
    setShowNameChange(true);
  }
  const [displayName, setDisplayName] = React.useState(currentUser.displayName);
  const onchangesetDisplayName = (event: React.FormEvent<HTMLInputElement>) => {
    setDisplayName(event.currentTarget.value);
  };
  const changeUserName = () => {
    let base = process.env.REACT_APP_SERVER_URL as string;
    axios.post(base + '/user/updateUserDetails/' + id, { 'displayName': displayName }, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }).then((result) => { currentUser.displayName = displayName; setShowNameChange(false) });
  }


  let [doingEmailChange, setShowEmailChange] = React.useState(false)
  const [email, setEmail] = React.useState(currentUser.email);
  const onchangesetEmail = (event: React.FormEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };
  const doEmailChange = () => {
    setShowEmailChange(true);
  }
  const changeUserMail = () => {
    let base = process.env.REACT_APP_SERVER_URL as string;

    axios.post(base + '/user/changeUserDetails/' + id, { 'email': email }, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }).then((result) => { currentUser.email = email; setShowEmailChange(false); });

  }








  const changeUserPassword = () => {
    let base = process.env.REACT_APP_SERVER_URL as string;
    axios.patch(base + '/user/changeUserPassword/' + id, {}, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }).then((result) => { changeUserPasswordMessage = result.data; });

  }
  let changeUserPasswordMessage = "";


  const setPlan = () => {
    switch (currentUser.stripeSubscriptionPlan) {
      case 'free':
        return 'Free';
      case 'plan 1':
        return 'Lite';
      case 'plan 2':
        return 'Creator'
      case 'plan 3':
        return 'Pro'
      case 'plan custom':
        return 'Custom'
      case 'appSumoPlan1':
        return 'Tier 1 - AppSumo Lifetime'
      case 'appSumoPlan2':
        return 'Tier 2 - AppSumo Lifetime'
      case 'appSumoPlan3':
        return 'Tier 3 - AppSumo Lifetime'
    }
  }


  return (
    <div className='mt-6 bg-white shadow overflow-hidden sm:rounded-lg'>
      <div className='px-4 py-5 sm:px-6'>
        <h3 className='text-lg leading-6 font-medium text-gray-900'>
          User Information
        </h3>
        <p className='mt-1 max-w-2xl text-sm text-gray-500'>
          Personal details and customizations
        </p>
      </div>
      <div className='border-t border-gray-200'>
        <dl>
          <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
            <dt className='text-sm font-medium text-gray-500'>Full name</dt>
            <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
              <span className={classNames(doingNameChange ? "hidden" : "")}>{currentUser.displayName} <PencilIcon width='24px' onClick={() => { doNameChange(); }} className='inline-block'></PencilIcon></span>
              <span className={classNames(!doingNameChange ? "hidden" : "", "inline-block")}><input type='text' id='userNameElement' value={displayName} className='inline-block' onChange={(e)=>onchangesetDisplayName(e)}></input> <SaveIcon width='24px' onClick={() => changeUserName()} className='inline-block'></SaveIcon></span>
            </dd>
          </div>
          <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
            <dt className='text-sm font-medium text-gray-500'>
              Subscription plan
            </dt>
            <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
              {setPlan()}
            </dd>
          </div>
          <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
            <dt className='text-sm font-medium text-gray-500'>Email address</dt>
            <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
              <span className={classNames(doingEmailChange ? "hidden" : "")}>{currentUser.email} <PencilIcon width='24px' onClick={() => { doEmailChange(); doingEmailChange = true }} className='inline-block'></PencilIcon></span>
              <span className={classNames(!doingEmailChange ? "hidden" : "", "inline-block")}><input type='text' id='userEmailElement' value={email} className='inline-block' onChange={(e)=>onchangesetEmail(e)}></input><SaveIcon width='24px' onClick={() => changeUserMail()} className='inline-block'></SaveIcon></span>
            </dd>
          </div>
          <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
            <dt className='text-sm font-medium text-gray-500'>
              Manage Subscription
            </dt>
            <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
              {currentUser.stripeCustomerId ?
                <StripePortal />
                : currentUser.invoiceItemUuid ?
                  <a
                    href={`https://appsumo.com/account/redemption/${currentUser.invoiceItemUuid}#change-plan`}
                    className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                    Upgrade your AppSumo Plan
                  </a> :
                  'You do not have a plan yet!'
              }
            </dd>
          </div>
          <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
            <dt className='text-sm font-medium text-gray-500'>Password</dt>
            <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
              <button className={classNames(changeUserPasswordMessage !== "" ? "hidden" : "", "inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500")} id="btnChangePassword" onClick={() => changeUserPassword()} >Request password change</button>
              <span className={classNames(changeUserPasswordMessage === "" ? "hidden" : "")}>{changeUserPasswordMessage}</span>
            </dd>
          </div>

        </dl>
      </div>
    </div>
  );
};

export default Settings;
