import { createContext } from 'react';

export type AdminQuickSearch = {
  searchKeysMap: Map<string, string>;
  updateSearchKeysMap: (key: string, value: string) => void;
};

export const AdminQuickSearchContext = createContext<AdminQuickSearch>({
  searchKeysMap: new Map([['userName', ''], ['contentName', '']]) as Map<string, string>,
  updateSearchKeysMap: (key: string, value: string) => {},
});
