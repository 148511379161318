import  'axios';
import axios, { isAxiosError } from '../axios/axios-config';


export const getUserInfos = async (token: string) => {

    // console.log('token od admin', token)

    const result = await axios.get(`/admin/getAllUserInfos`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    return result.data;
};


export const changeFirstTakedownStatus = async (userId: string, userContentId: string, token: string) => {
    try {
    const result = await axios.patch(`/admin/changeFirstTakedownStatus/${userId}`, {
        userContentId
    }, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    return result.status;
    } catch(err) {
        if(isAxiosError(err)) {
            return err.response?.data;
            }
    }
};

export const getUserContentById = async(userId: string, contentId: string, token: string, showall = false) => {
    try {
        if(showall) { console.warn('should get all contents'); }
        const result = await axios.post(`/admin/getUserContentById/${userId}`, {
            contentId
        },{ headers: {
            Authorization: `Bearer ${token}`
        },
    })
    return result.data;
    } catch(err) {
        if(isAxiosError(err)) {
            return err.response?.data;
        }
    }
}

export const getOutstandingUserContent = async(
    token: string,
    pageToken?: string,
    userName?: string,
    contentName?: string
) => {
    try {
        let url = `/admin/outstandingUserContent?auth=true`
        if (userName && userName.length > 0) {
            url += `&userName=${userName}`
        }
        if (contentName && contentName.length > 0) {
            url += `&contentName=${contentName}`
        }
        if(pageToken) {
            url += `&pageToken=${pageToken}`
        }
        const result = await axios.get(url,
        { headers: {
            Authorization: `Bearer ${token}`
        },
    })
    return result.data;
    } catch(err) {
        if(isAxiosError(err)) {
            return err.response?.data;
        }
    }
}

export const getNewUserContent = async(
    token: string,
    pageToken?: string,
    userName?: string,
    contentName?: string
) => {
    try {
        let url = `/admin/newUserContent?auth=true`
        if (userName && userName.length > 0) {
            url += `&userName=${userName}`
        }
        if (contentName && contentName.length > 0) {
            url += `&contentName=${contentName}`
        }
        if(pageToken) {
            url += `&pageToken=${pageToken}`
        }
        const result = await axios.get(url,
        { headers: {
            Authorization: `Bearer ${token}`
        },
    })
    return result.data;
    } catch(err) {
        if(isAxiosError(err)) {
            return err.response?.data;
        }
    }
}

export const saveUpdatedResult = async (userId: string, userContentId: string, retrievedResultId: string, verified: boolean, infringementStatus: string, confirmationUrls: string[], token: string) => {
    try {
    const result = await axios.patch(`/admin/updateRetrievedResult/${userId}`, {
        userContentId,
        retrievedResultId,
        verified,
        infringementStatus,
        confirmationUrls }, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    })
    
    return result.status;
    } catch(err) {
        if(isAxiosError(err)) {
            return err.response?.data;
        }
    }
}

export const updateMultipleRetrievedResults = async (userId: string, userContentId: string, ids: string[], status: string, token: string, confUrl?: string) => {
    try {
        const result = await axios.patch(`/admin/updateMultipleRetrievedResults/${userId}`, {
            userContentId,
            ids,
            status,
            confUrl
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }, 
        });

        return result.data;

    } catch (err) {
        if(isAxiosError(err)) {
        return err.response?.data;
        }
    }
}