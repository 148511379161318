import React, { useContext} from 'react';
import { UserIdContext } from '../../contexts/UserIdContext';
import Loading from '../loading/loading';
import { useUser } from './../../swrHooks/useUser';
import { Route } from 'react-router-dom';

interface IProps {
    component: React.ComponentType;
    path: string;
    computedMatch?: {
        isExact: boolean;
      };
}

export const WithServerDataChecked = ({component, ...args }: IProps ) => {

    const { id, userToken } = useContext(UserIdContext);
    const {isLoadingData, isError } = useUser(id as string, userToken as string);

    // if(isLoadingData) return <Loading/<
    
    if(isError) {
        throw new Error('the request failer and returned an error');
    }

    return isLoadingData ? <Loading/> : 
    <Route
    component={component} {...args}
    />

}

