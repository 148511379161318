import { createContext } from 'react';


// not message usestate
// success usestate
// show notification

export type ResultNotification = {
    notMessage: string,
    setNotMessage: (message: string) => void,
    success: boolean,
    setSuccess: (status: boolean) => void,
    showNotification: boolean,
    setShowNotification: (status: boolean) => void
}

export const ResultNotificationContext = createContext<ResultNotification>({
    notMessage: '',
    setNotMessage: () => {},
    success: false,
    setSuccess: () => {},
    showNotification: false,
    setShowNotification: () => {}
})